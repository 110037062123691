import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx"
import { VideoListListContainer, VideoListListItem } from '../../molecules';
import { getVideoProduct } from "../../../actions/__Video"

const useStyles = makeStyles({
    root: {
    },
});

export default ({ className, ...others }) => {
    const classes = useStyles();
    const [videos, setVideos] = useState([])
    const getData = async () => {
        const data = await getVideoProduct()
        console.log(data)
        setVideos(data.videos)
    }
    useEffect(() => {
        getData()
        return () => { }
    }, [])
    return (
        <VideoListListContainer>
            {videos.map(({ id, title, videoUploader, views, imgSrc }) => {
                return (
                    <VideoListListItem title={title} videoUploader={videoUploader} views={views} imgSrc={imgSrc} key={id} id={id} />
                )
            })}
        </VideoListListContainer>
    )
}