import axios from 'axios'
import random from "./Random"

export const getPaymentList = async (userId) => {
    try {
        const payment = { id: "1", type: "creditCard", last4number: "1234" }
        let data = [{ ...payment, id: random() }, { ...payment, id: random() }, { ...payment, id: random() }]
        return data;
    }
    catch (err) {
    }
}
