import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';
import { Button, Text } from '../atoms';
import { getProfile } from "../../actions/Account"
import clsx from "clsx"

const useStyles = makeStyles({
    root: {
        width: "100%",
        position: "relative",
        padding: 0,
        overflow: "hidden"
    },
    avatar: {
        position: "relative",
        width: '70px',
        height: '70px',
        margin: "41px auto 0 auto",

    },
    bio: {
        position: "relative",
        display: "block",
        "width": "calc(100% - 120px)",
        "height": "51px",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "normal",
        "fontSize": "12px",
        "lineHeight": "17px",
        "textAlign": "center",
        "color": "#FFFFFF",
        "flex": "none",
        "order": "1",
        "alignSelf": "flex-end",
        "flexGrow": "0",
        "margin": "0px 60px"
    },
    button: {
        "display": "block",
        "position": "relative",
        "width": "50px",
        "height": "17px",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "bold",
        "fontSize": "12px",
        "lineHeight": "17px",
        "color": "#21EBA2",
        "flex": "none",
        "order": "2",
        "alignSelf": "center",
        "flexGrow": "0",
        "margin": "0px auto"
    },
});

export default ({ className }) => {
    const classes = useStyles();
    const [name, setName] = useState("")
    const [introduction, setIntroduction] = useState("")
    const getData = async () => {
        const data = await getProfile()
        setName(data.userName)
        setIntroduction(data.introduction)
    }
    useEffect(() => {
        getData()
        return () => { }
    }, [])
    return (
        <div className={clsx(classes.root, className)}>
            {/* Nameが何もなかったら、Xになります */}
            <Avatar className={classes.avatar}>{!!name ? name.slice(0, 1) : "X"}</Avatar>

            {/* コメント？自己紹介文的な？twitterでいうbio */}
            <Text color="glay" className={classes.bio}>{introduction}</Text>

            <span className={classes.button}>Follow +</span>
        </div>
    )
}