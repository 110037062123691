import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx"

const useStyles = makeStyles({
    root: {
        textAlign: "center"
    },
    icon: {
        "&>*": {
            width: "20px",
            height: "20px",
        }
    },
    text: {
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "bold",
        "fontSize": "12px",
        "lineHeight": "17px",
        "color": "#000000"
    },
});

export default ({ icon, text, className, ...others }) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, className)} {...others}>
            <div className={classes.icon}>
                {icon}
            </div>
            <div className={classes.text}>
                {text}
            </div>
        </div>
    )
}