import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx"

const useStyles = makeStyles({
    root: {
        "position": "relative",
        "&:before": {
            "content": "\"\"",
            "display": "block",
            "paddingTop": "100%"
        }
    },
    image: {
        "position": "absolute",
        "width": "100%",
        "height": "100%",
        "object-fit": "cover",
        "top": "0",
        "left": "0",
        "bottom": "0",
        "right": "0"
    },
});

export default ({ imgSrc, className, ...others }) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, className)} {...others}>
            <img className={classes.image} src={imgSrc} />
        </div>
    )
}