import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import PopoverWrapper from "../PopoverWrapper"
import { VerticalCenter, PopoverWrapper } from '../../../comoponents/molecules/'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "86px",
        position: "relative",
    },
    contentWrapper: {
        width: "200px",
        hright: '100%',
        marginLeft: "27px",
        textAlign: "left",
    },
    content: {
        width: "calc(100% - 27px - 27px)",
        marginLeft: "27px",
        textAlign: "left",
        float: "left"
    },
    arrowWrapper: {
        zIndex: "1",
        position: "absolute",
        float: "right",
        top: "37px",
        right: "29px"
    }
}));

export default ({ children, popoverContent, ...other }) => {
    const classes = useStyles();
    return (
        <PopoverWrapper
            {...other}
            popoverContent={popoverContent}
        >
            <div className={classes.root}>
                <VerticalCenter className={classes.contentWrapper}>
                    <div className={classes.content}>
                        {children}
                    </div>
                </VerticalCenter>
                <div className={classes.arrowWrapper}>
                    <VerticalCenter>
                        <KeyboardArrowDownIcon />
                    </VerticalCenter>
                </div>
            </div>
        </PopoverWrapper>
    );
}