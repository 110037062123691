import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx"

const useStyles = makeStyles({
    root: {
        // 3番目まで表示、以降は非表示
        "& > *:nth-child(n + 4)": {
            display: "none"
        }
    },
});

export default ({ className, ...others }) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, className)} {...others}>

        </div>
    )
}