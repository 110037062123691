import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx"
import { ProductImage, ProductPrice, ProductAmount, ProductAddToCart, Divider, } from "../../molecules"
import ImageList from '../../molecules/ImageListContainer';
import { getProduct } from "../../../actions/Product"
import { Store } from "../../../store"
import { useHistory, } from "react-router-dom"

const useStyles = makeStyles({
    root: {
    },
});

export default ({ variantId, id, className, ...others }) => {
    const classes = useStyles();
    const [imgSrc, setimgSrc] = useState("")
    const [name, setName] = useState("")
    const [brandName, setBrandName] = useState("Brand Name")
    const [price, setPrice] = useState(0)
    const [discountedPrice, setDiscountedPrice] = useState(0)
    const [amount, setAmount] = useState(0)

    const { globalState, setGlobalState } = useContext(Store)

    const history = useHistory();

    const __getProduct = async () => {
        const data = await getProduct(id)
        setimgSrc(data.imgSrc)
        setName(data.name)
        setBrandName(data.brandName)
        setPrice(data.price)
        setDiscountedPrice(data.discountedPrice)
    }

    const AddToCart = () => {
        setGlobalState({ type: "ADD_TO_CART", payload: { variantId, id, amount } })
        setAmount(0)
        history.push(`/Cart` || '/');
    }

    useEffect(() => {
        console.log(globalState)
        __getProduct()
        return () => { }
    }, [])
    return (
        <div className={clsx(classes.root, className)} {...others}>
            <ProductImage
                imgSrc={imgSrc}
                name={name}
                brandName={brandName}
            />
            <Divider />
            <ProductPrice
                discountedPrice={discountedPrice}
                price={price}
            />
            <Divider />
            <ProductAmount value={amount} setValue={setAmount} />
            <Divider />
            <ProductAddToCart onAddToCart={AddToCart} />
            <Divider />
            <ImageList />
        </div>
    )
}