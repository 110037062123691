import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { CartContainer, CartItem } from '../molecules'
import clsx from "clsx"
import { Store } from "../../store"
import { getProduct } from "../../actions/Product"


const useStyles = makeStyles({
    root: {
        overflowY: "scroll",
        height: "300px",
        paddingBottom: "60px",
    },
    button: {
        "position": "absolute",
        "bottom": "16px",
        "left": "0",
    },
});

export default ({ open, setOpen, openPayment, className, ...others }) => {
    const classes = useStyles();
    const { globalState, setGlobalState } = useContext(Store)
    const [data, setData] = useState([])

    const __getProducts = async () => {
        const datas = []
        for (let index in globalState.cart) {
            let { id, amount } = globalState.cart[index]
            const setAmount = (amount) => {
                setGlobalState({ type: "UPDATE_CART", payload: { index, amount } })
            };
            datas.push({ ...(await getProduct(id)), id, amount, setAmount, index })
        }
        setData(datas)
    }

    useEffect(
        () => {
            __getProducts()
        },
        [globalState.cart]
    );
    return (
        <CartContainer className={clsx(classes.root, className)} {...others}>
            {data.map(d => {
                return (
                    <CartItem {...d} />)
            })}
        </CartContainer>
    )
}