import React, { useState, useContext, useEffect } from 'react'
import { Button } from '../atoms'
import clsx from "clsx"
import { makeStyles } from '@material-ui/core/styles';
import { AppearFromBottom, ProductNoIcon, VerticalCenter, } from '../molecules'
import { ProductAmount, Divider, } from "../molecules"
import { Store } from "../../store"

const useStyles = makeStyles({
    root: {
        "&>*:nth-child(2)": {
            height: "530px"
        }
    },
    swipe: {
        height: "96px"
    },
    productWrapper: {
        overflowY: "scroll",
        height: "300px",
        paddingBottom: "60px",
    },
    button: {
        "position": "absolute",
        "bottom": "16px",
        "left": "0",
    },
    description: {
        "width": "100%",
        "height": "68px",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "normal",
        "fontSize": "12px",
        "lineHeight": "17px",
        "color": "#000000"
    },
    total: {
        "width": "100%",
        "height": "20px",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "normal",
        "fontSize": "14px",
        "lineHeight": "20px",
        "textAlign": "right",
        "color": "#000000"
    },
    header: {
        height: "100px"
    },
    backButton: {
        float: "left",
        width: "18px"
    },
    backTextWrapper: {
        float: "left",
        marginLeft: "17px",
        width: "calc(100% - 18px - 17px - 2px)",
        "&>*": {
            textAlign: "left"
        }
    },
    backText: {
        "width": "220px",
        "height": "23px",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "bold",
        "fontSize": "16px",
        "lineHeight": "23px",
        "color": "#000000"
    },
});

export default ({ className, ...others }) => {
    const classes = useStyles();
    const { globalState, setGlobalState } = useContext(Store)
    const setProducts = (products) => {
        setGlobalState({ type: "SET_PRODUCTS", payload: { products } })
    }
    const addToCart = (amount) => {
        setGlobalState({ type: "ADD_TO_CART", payload: { variantId: globalState.products[globalState.productDetailModal.index].variantId, id: globalState.products[globalState.productDetailModal.index].id, amount: globalState.products[globalState.productDetailModal.index].amount } })
    }
    const setAmount = (amount) => {
        setProducts(globalState.products.map(product => {
            if (product.id == globalState.products[globalState.productDetailModal.index].id)
                product.amount = amount
            return product
        }))
    }
    const setOpen = (open) => {
        setGlobalState({ type: "SET_PRODUCT_DETAIL", payload: { productDetailModal: { open: false } } })
    }
    useEffect(() => {
        console.log(globalState.products[globalState.productDetailModal.index])
        return () => { }
    }, [globalState.productDetailModal.index])
    return (
        <AppearFromBottom
            open={globalState.productDetailModal.open}
            onClose={() => { setOpen(false) }} className={clsx(classes.root, className)} {...others}
        >            {globalState.productDetailModal.open ? <>
            <div className={classes.header} onClick={() => { setOpen(false) }}>
                <VerticalCenter className={classes.backButton}>
                    <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.78112 0.968997C6.85097 1.03867 6.90638 1.12143 6.94419 1.21255C6.982 1.30366 7.00146 1.40135 7.00146 1.5C7.00146 1.59865 6.982 1.69633 6.94419 1.78745C6.90638 1.87857 6.85097 1.96133 6.78112 2.031L2.81062 6L6.78112 9.969C6.85085 10.0387 6.90617 10.1215 6.94391 10.2126C6.98164 10.3037 7.00107 10.4014 7.00107 10.5C7.00107 10.5986 6.98164 10.6963 6.94391 10.7874C6.90617 10.8785 6.85085 10.9613 6.78112 11.031C6.71139 11.1007 6.6286 11.156 6.5375 11.1938C6.44639 11.2315 6.34874 11.2509 6.25012 11.2509C6.1515 11.2509 6.05385 11.2315 5.96274 11.1938C5.87164 11.156 5.78885 11.1007 5.71912 11.031L1.21912 6.531C1.14928 6.46133 1.09386 6.37856 1.05605 6.28745C1.01824 6.19633 0.998779 6.09865 0.998779 6C0.998779 5.90135 1.01824 5.80366 1.05605 5.71255C1.09386 5.62143 1.14928 5.53867 1.21912 5.469L5.71912 0.968997C5.78879 0.899153 5.87155 0.843738 5.96267 0.805928C6.05379 0.768119 6.15147 0.748657 6.25012 0.748657C6.34877 0.748657 6.44645 0.768119 6.53757 0.805928C6.62869 0.843738 6.71145 0.899153 6.78112 0.968997Z" fill="black" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.75 6C1.75 5.80109 1.82902 5.61032 1.96967 5.46967C2.11032 5.32902 2.30109 5.25 2.5 5.25H18.25C18.4489 5.25 18.6397 5.32902 18.7803 5.46967C18.921 5.61032 19 5.80109 19 6C19 6.19891 18.921 6.38968 18.7803 6.53033C18.6397 6.67098 18.4489 6.75 18.25 6.75H2.5C2.30109 6.75 2.11032 6.67098 1.96967 6.53033C1.82902 6.38968 1.75 6.19891 1.75 6Z" fill="black" />
                    </svg>
                </VerticalCenter>
                <VerticalCenter className={classes.backTextWrapper}>
                    <div className={classes.backText}>
                        Back
                    </div>
                </VerticalCenter>
            </div>
            <div className={classes.productWrapper}>
                <ProductNoIcon
                    imgSrc={globalState.products[globalState.productDetailModal.index].imgSrc}
                    name={globalState.products[globalState.productDetailModal.index].name}
                    brandName={globalState.products[globalState.productDetailModal.index].brandName}
                    imageSize={"big"}
                />
                <Divider />
                <div className={classes.description}>{globalState.products[globalState.productDetailModal.index].description}</div>
                <Divider />
                <ProductAmount value={globalState.products[globalState.productDetailModal.index].amount} setValue={setAmount} />
                <Divider />
                <div className={classes.total}>合計 ¥ {globalState.products[globalState.productDetailModal.index].amount * globalState.products[globalState.productDetailModal.index].price}</div>
            </div>
            <Button className={classes.button} variant="contained" color="secondary" fullWidth onClick={() => {
                addToCart()
                setAmount(0)
                setOpen(0)
            }}>Add to cart</Button></>
            :
            <></>
            }
        </AppearFromBottom >
    )
}