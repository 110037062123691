import React, { createContext, useReducer, useEffect } from 'react'

const APP_KEY = 'sampleApp'

const appState = localStorage.getItem(APP_KEY)
const defaultState = {
    cart: [],
    loading: false,
    address: "",
    zipCode: "",
    cardNumber: "",
    cardName: "",
    cardExpires: "",
    cardSecurity: "",
    signUpUserName: "",
    signUpEmail: "",
    signUpPassword: "",
    token: "",
    userId: "3730993479764",
    userName: "",
    paymentId: "",
    shippingTime: "",
    progress: 100,
    notification: {
        open: false,
        message: "",
        severity: "",
    },
    video: {
        userName: "",
        videoSrc: "",
        like: "",
        description: "",
        videoId: "",
        isLiked: "",
        nextVideoId: "",
        products: [],
    },
    openProductModal: false,
    searchResult: {
        def: {
            name: "",
            videos: []
        },
        live: {
            title: "",
            startTime: "",
            imgSrc: ""
        },
        preference: {
            name: "",
            videos: []
        },
    },
    productDetailModal: {
        open: false,
        id: 0,
        // description: "",
        amount: 0,
        imgSrc: "",
        name: "",
        brandName: "",
        price: 0,
        discountedPrice: 0,
    },
    products: [],
    openAddressSelectModal: false,
    openTimeSelectModal: false,
    openPaymentSelectModal: false,
}
const initialState = appState ? { ...defaultState, ...JSON.parse(appState) } : defaultState


const reducer = (state, action) => {
    switch (action.type) {
        case 'ADD_TO_CART':
            if (action.payload.amount > 0) {
                const found = state.cart.find(cartItem => cartItem.id == action.payload.id);
                console.log(found, !found)
                if (!found) return { ...state, cart: [...state.cart, { variantId: action.payload.variantId - 0, id: action.payload.id - 0, amount: action.payload.amount - 0 }] }
                const updatedCart = state.cart.map(cartItem => {
                    return cartItem.id == action.payload.id
                        ? { variantId: action.payload.variantId - 0, id: action.payload.id - 0, amount: cartItem.amount - 0 + action.payload.amount - 0 }
                        : cartItem
                })
                return { ...state, cart: [...updatedCart] }
            }
            return state
        case 'UPDATE_CART':
            if (action.payload.amount > 0) {
                let cart = state.cart
                cart[action.payload.index].amount = action.payload.amount
                return { ...state, cart: [...cart] }
            } else {
                let cart = [...state.cart]
                cart.splice(action.payload.index, 1)
                return { ...state, cart }
            }
        case 'EMPTY_CART':
            return { ...state, cart: [] }
        case 'SET_ADDRESS':
            return { ...state, address: action.payload.address }
        case 'SET_ZIP_CODE':
            return { ...state, zipCode: action.payload.zipCode }
        case 'SET_PAYMENT':
            return {
                ...state,
                paymentId: action.payload.paymentId,
            }
        case 'SET_SHIPPING_TIME':
            return { ...state, shippingTime: action.payload.time }
        case 'SET_SIGNUP_USERNAME':
            return { ...state, signUpUserName: action.payload.userName, }
        case 'SET_SIGNUP_EMAIL':
            return { ...state, signUpEmail: action.payload.email, }
        case 'SET_SIGNUP_PASSWORD':
            return { ...state, signUpPassword: action.payload.password, }
        case 'SET_TOKEN':
            return { ...state, token: action.payload.token, }
        case 'SET_SEARCH_RESULT':
            return { ...state, searchResult: action.payload.searchResult, }
        case 'SET_VIDEO':
            return { ...state, video: action.payload.video, }
        case 'SET_PRODUCTMODAL':
            return { ...state, openProductModal: action.payload.open, }
        case 'SET_PRODUCTS':
            return { ...state, products: action.payload.products, }
        case 'SET_PRODUCT_DETAIL':
            return { ...state, productDetailModal: { ...state.productDetailModal, ...action.payload.productDetailModal, }, }
        case 'SET_OPEN_ADDRESS_SELECT_MODAL':
            return { ...state, openAddressSelectModal: action.payload.openAddressSelectModal, }
        case 'SET_OPEN_TIME_SELECT_MODAL':
            return { ...state, openTimeSelectModal: action.payload.openTimeSelectModal, }
        case 'SET_OPEN_PAYMENT_SELECT_MODAL':
            return { ...state, openPaymentSelectModal: action.payload.openPaymentSelectModal, }
        case 'SET_LOADING':
            return { ...state, loading: action.payload.loading, }
        case 'SET_NOTIFICATION':
            console.log(action.payload)
            return { ...state, notification: action.payload, }
        case 'SET_PROGRESS':
            return { ...state, progress: action.payload.progress, }
        default:
            return state
    }
}

export const Store = createContext({
    globalState: initialState,
    setGlobalState: () => null
})

export const StoreProvider = ({ children }) => {
    const [globalState, setGlobalState] = useReducer(reducer, initialState)
    useEffect(() => {
        localStorage.setItem(APP_KEY, JSON.stringify({
            cart: globalState.cart
        }))
    }, [globalState])
    return (
        <Store.Provider value={{ globalState, setGlobalState }}>{children}</Store.Provider>
    )
}