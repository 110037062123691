import axios from 'axios'
import URLJoin from '../URLJoin'
import AccessServer from '../AccessServer'

export default async (username, password,) => {
    try {
        if (AccessServer()) {
            const param = {
                "user": {
                    "username": username,
                    "password": password,
                    "customer": null,
                    "option": null
                }
            }
            const uri = URLJoin(process.env.REACT_APP_URL_BACKEND, "/api/user/1")
            console.log("signin", JSON.stringify(param))
            const { data: data, ...other } = await axios.post(uri, param)
            console.log(data)
            const { user: { token: token, username: name }, ...res } = data
            console.log(token, name, other, res)
            return { token, username: name }
        } else {
            const param = { username, password }
            const data = { token: "LQwDde6x8eV4ROOCOdSW" }//ダミー
            return data;
        }
    }
    catch (err) {
        throw new Error(err)
        return { error: true, payload: err }
    }
}