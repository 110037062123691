import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Text, Button } from '../../atoms';
import { SignUpMessage, VerticalCenter } from '../../molecules'

const useStyles = makeStyles({
    root: {
        height: "100%",
        width: "100%",
    },
    wrapper: {
        width: "100%",
        height: "100%"
    }
});

export default () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <VerticalCenter className={classes.wrapper}>
                <SignUpMessage />
            </VerticalCenter>
        </div >
    )
}