import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useHistory, } from "react-router-dom"

const useStyles = makeStyles({
    root: {
        position: "relative",
        width: "100%",
        display: "block",
        margin: "0px",
        padding: "0px",
        "&:before": {
            "content": "\"\"",
            "display": "block",
            "paddingTop": "147.78%",
        }
    },
    image: {
        position: "absolute",
        top: "0",
        left: "0",
        right: "0",
        objectFit: "cover",
        display: "block",
        width: "100%",
        height: "100%"
    },
    overlay: {
        zIndex: 2,
        position: "absolute",
        left: "0",
        right: "0",
        bottom: "0",
        color: "#f1f1f1",
        transition: ".5s ease",
        opacity: "1",
        color: "white",
        fontSize: "12px",
        padding: "12px",
        textSlign: "center",
        background: "-moz-linear-gradient(top, transparent, #000000)",
        background: "-webkit-linear-gradient(top, transparent, #000000)",
        background: "linear-gradient(to bottom, transparent, #000000)",
    }

});

export default ({ id, views, imgSrc }) => {
    const classes = useStyles();
    const history = useHistory()
    return (
        <div className={classes.root} onClick={() => { history.push(`/Video/${id}/`) }}>
            <img src={imgSrc} alt="thumbnail" className={classes.image} />
            <div className={classes.overlay}><VisibilityIcon />{views}</div>
        </div >
    )
}