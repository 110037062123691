import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { SearchInput } from "../molecules"
import clsx from "clsx"
import { Search } from "../../actions/Search"
import { Store } from "../../store"

const useStyles = makeStyles({
    root: {
    },
});

export default ({ className, ...others }) => {
    const classes = useStyles();
    const [value, setValue] = useState("")
    const { globalState, setGlobalState } = useContext(Store)
    const search = async (v) => {
        const result = await Search(v)
        setGlobalState({ type: "SET_SEARCH_RESULT", payload: { searchResult: result } })
    }
    return (
        <>
            <SearchInput value={value} setValue={(v) => { search(v); setValue(v) }} />
        </>
    )
}