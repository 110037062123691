import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx"

const useStyles = makeStyles({
    root: {
        "overflowY": "scroll",
    },
    even: {
        "whiteSpace": "nowrap",
        "& > *": {
            "display": "inline-block",
            "overflowY": "auto"
        },
        "& > *:nth-child(odd)": {
            display: "none"
        }
    },
    odd: {
        "whiteSpace": "nowrap",
        "& > *": {
            "display": "inline-block",
            "overflowY": "auto"
        },
        "& > *:nth-child(even)": {
            display: "none"
        }
    }
});

export default ({ className, children, ...others }) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, className)} {...others}>
            <div className={classes.odd}>
                {children}
            </div>
            <div className={classes.even}>
                {children}
            </div>
        </div>
    )
}