import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { HorizontalScroller, HorizontalScrollerItem } from '../../molecules'

const useStyles = makeStyles({
    root: {
        width: "100%",
    },
    item: {
        padding: "6px",
        fontFamily: "Noto Sans Japanese",
        fontStyle: "normal",
        fontEeight: "bold",
        fontSize: "14px",
        lineHeight: "20px",
        color: "#000000"
    },
});

export default ({ datas }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <HorizontalScroller>
                {datas.map((data) => {
                    return (
                        <HorizontalScrollerItem className={classes.item}>
                            <span>{data}</span>
                        </HorizontalScrollerItem>
                    )
                })}
            </HorizontalScroller>
        </div>
    )
}