import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { VerticalCenter } from '../molecules';
import { useHistory, } from "react-router-dom"
import clsx from "clsx"

const useStyles = makeStyles({
    root: {
        "position": "fixed",
        "zIndex": "100",
        "width": "100%",
        "height": "56px",
        "left": "0px",
        "bottom": "0px",
        "background": "#181818",
        "boxShadow": "0px 0px 10px rgba(0, 0, 0, 0.25)",
    },
    "footerItem": {
        width: "20%",
        height: "100%",
        float: "left",
        "& svg>path": {
            fill: "#FFFFFF",
        },
        "&.active svg>path": {
            fill: "#21EBA2",
        },
    },
    'home': {
    },
    'search': {
    },
    'video': {
    },
    'comment': {
    },
    'profile': {
    },
    footerBox: {
        "width": "100%",
        "height": "56px",
        "background": "#181818",
    },
});

export default ({ tab, className, ...others }) => {
    const classes = useStyles();
    const history = useHistory()
    return (<>
        {/* <div className={classes.footerBox}></div> */}
        <footer className={clsx(classes.root, className)} {...others}>
            <VerticalCenter
                className={clsx(classes.home, classes.footerItem, { "active": tab == 1 })}
                onClick={() => { history.push(`/`) }}
            >
                <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2 12.5V6H3V12.5C3 12.6326 3.05268 12.7598 3.14645 12.8536C3.24021 12.9473 3.36739 13 3.5 13H12.5C12.6326 13 12.7598 12.9473 12.8536 12.8536C12.9473 12.7598 13 12.6326 13 12.5V6H14V12.5C14 12.8978 13.842 13.2794 13.5607 13.5607C13.2794 13.842 12.8978 14 12.5 14H3.5C3.10218 14 2.72064 13.842 2.43934 13.5607C2.15804 13.2794 2 12.8978 2 12.5ZM13 1.5V5L11 3V1.5C11 1.36739 11.0527 1.24021 11.1464 1.14645C11.2402 1.05268 11.3674 1 11.5 1H12.5C12.6326 1 12.7598 1.05268 12.8536 1.14645C12.9473 1.24021 13 1.36739 13 1.5Z" fill="#21EBA2" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.29299 0.500001C7.48052 0.31253 7.73483 0.207214 7.99999 0.207214C8.26516 0.207214 8.51946 0.31253 8.70699 0.500001L15.354 7.146C15.4479 7.23989 15.5006 7.36723 15.5006 7.5C15.5006 7.63278 15.4479 7.76011 15.354 7.854C15.2601 7.94789 15.1328 8.00063 15 8.00063C14.8672 8.00063 14.7399 7.94789 14.646 7.854L7.99999 1.207L1.35399 7.854C1.2601 7.94789 1.13277 8.00063 0.999991 8.00063C0.867215 8.00063 0.739877 7.94789 0.645991 7.854C0.552104 7.76011 0.499359 7.63278 0.499359 7.5C0.499359 7.36723 0.552104 7.23989 0.645991 7.146L7.29299 0.500001Z" fill="#21EBA2" />
                </svg>
            </VerticalCenter>
            <VerticalCenter
                className={clsx(classes.search, classes.footerItem, { "active": tab == 2 })}
                onClick={() => { history.push(`/Search`) }}
            >
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.442 10.442C10.5349 10.3491 10.6452 10.2753 10.7666 10.225C10.888 10.1746 11.0181 10.1487 11.1495 10.1487C11.2809 10.1487 11.411 10.1746 11.5324 10.225C11.6538 10.2753 11.7641 10.3491 11.857 10.442L15.707 14.292C15.8946 14.4795 16.0001 14.7339 16.0002 14.9992C16.0003 15.2644 15.895 15.5189 15.7075 15.7065C15.52 15.8942 15.2656 15.9996 15.0004 15.9997C14.7351 15.9998 14.4806 15.8945 14.293 15.707L10.443 11.857C10.35 11.7642 10.2763 11.6539 10.2259 11.5325C10.1756 11.4111 10.1497 11.2809 10.1497 11.1495C10.1497 11.0181 10.1756 10.888 10.2259 10.7666C10.2763 10.6452 10.35 10.5349 10.443 10.442H10.442Z" fill="#F5F5F5" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.5 12C7.22227 12 7.93747 11.8577 8.60476 11.5813C9.27205 11.3049 9.87837 10.8998 10.3891 10.3891C10.8998 9.87837 11.3049 9.27205 11.5813 8.60476C11.8577 7.93747 12 7.22227 12 6.5C12 5.77773 11.8577 5.06253 11.5813 4.39524C11.3049 3.72795 10.8998 3.12163 10.3891 2.61091C9.87837 2.10019 9.27205 1.69506 8.60476 1.41866C7.93747 1.14226 7.22227 1 6.5 1C5.04131 1 3.64236 1.57946 2.61091 2.61091C1.57946 3.64236 1 5.04131 1 6.5C1 7.95869 1.57946 9.35764 2.61091 10.3891C3.64236 11.4205 5.04131 12 6.5 12ZM13 6.5C13 8.22391 12.3152 9.87721 11.0962 11.0962C9.87721 12.3152 8.22391 13 6.5 13C4.77609 13 3.12279 12.3152 1.90381 11.0962C0.684819 9.87721 0 8.22391 0 6.5C0 4.77609 0.684819 3.12279 1.90381 1.90381C3.12279 0.684819 4.77609 0 6.5 0C8.22391 0 9.87721 0.684819 11.0962 1.90381C12.3152 3.12279 13 4.77609 13 6.5Z" fill="#F5F5F5" />
                </svg>
            </VerticalCenter>
            <VerticalCenter
                className={clsx(classes.video, classes.footerItem, { "active": tab == 3 })}
                onClick={() => { history.push(`/AddVideo`) }}
            >
                <svg width="26" height="18" viewBox="0 0 26 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.66725 0.25H15.3328C16.9428 0.25 18.25 1.5555 18.25 3.16725V14.8328C18.25 16.4428 16.9445 17.75 15.3328 17.75H3.66725C2.05725 17.75 0.75 16.4445 0.75 14.8328V3.16725C0.75 1.55725 2.0555 0.25 3.66725 0.25Z" fill="white" fill-opacity="0.8" />
                    <path d="M11.957 10.2198L23.0923 16.6808C24.0373 17.2286 25.25 16.5653 25.25 15.4611V2.53906C25.25 1.43656 24.0373 0.771559 23.0923 1.32106L11.957 7.78206C11.742 7.90478 11.5634 8.08216 11.4391 8.29622C11.3148 8.51029 11.2493 8.75341 11.2493 9.00094C11.2493 9.24846 11.3148 9.49159 11.4391 9.70565C11.5634 9.91971 11.742 10.0971 11.957 10.2198Z" fill="white" fill-opacity="0.8" />
                </svg>
            </VerticalCenter>
            <VerticalCenter
                className={clsx(classes.comment, classes.footerItem, { "active": tab == 4 })}
                onClick={() => { history.push(`/Message`) }}
            >
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14 1H2C1.73478 1 1.48043 1.10536 1.29289 1.29289C1.10536 1.48043 1 1.73478 1 2V10C1 10.2652 1.10536 10.5196 1.29289 10.7071C1.48043 10.8946 1.73478 11 2 11H4.5C4.81049 11 5.11672 11.0723 5.39443 11.2111C5.67214 11.35 5.91371 11.5516 6.1 11.8L8 14.333L9.9 11.8C10.0863 11.5516 10.3279 11.35 10.6056 11.2111C10.8833 11.0723 11.1895 11 11.5 11H14C14.2652 11 14.5196 10.8946 14.7071 10.7071C14.8946 10.5196 15 10.2652 15 10V2C15 1.73478 14.8946 1.48043 14.7071 1.29289C14.5196 1.10536 14.2652 1 14 1ZM2 0C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2L0 10C0 10.5304 0.210714 11.0391 0.585786 11.4142C0.960859 11.7893 1.46957 12 2 12H4.5C4.65525 12 4.80836 12.0361 4.94721 12.1056C5.08607 12.175 5.20685 12.2758 5.3 12.4L7.2 14.933C7.29315 15.0572 7.41393 15.158 7.55279 15.2274C7.69164 15.2969 7.84475 15.333 8 15.333C8.15524 15.333 8.30836 15.2969 8.44721 15.2274C8.58607 15.158 8.70685 15.0572 8.8 14.933L10.7 12.4C10.7931 12.2758 10.9139 12.175 11.0528 12.1056C11.1916 12.0361 11.3448 12 11.5 12H14C14.5304 12 15.0391 11.7893 15.4142 11.4142C15.7893 11.0391 16 10.5304 16 10V2C16 1.46957 15.7893 0.960859 15.4142 0.585786C15.0391 0.210714 14.5304 0 14 0L2 0Z" fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3 3.5C3 3.36739 3.05268 3.24021 3.14645 3.14645C3.24021 3.05268 3.36739 3 3.5 3H12.5C12.6326 3 12.7598 3.05268 12.8536 3.14645C12.9473 3.24021 13 3.36739 13 3.5C13 3.63261 12.9473 3.75979 12.8536 3.85355C12.7598 3.94732 12.6326 4 12.5 4H3.5C3.36739 4 3.24021 3.94732 3.14645 3.85355C3.05268 3.75979 3 3.63261 3 3.5ZM3 6C3 5.86739 3.05268 5.74021 3.14645 5.64645C3.24021 5.55268 3.36739 5.5 3.5 5.5H12.5C12.6326 5.5 12.7598 5.55268 12.8536 5.64645C12.9473 5.74021 13 5.86739 13 6C13 6.13261 12.9473 6.25979 12.8536 6.35355C12.7598 6.44732 12.6326 6.5 12.5 6.5H3.5C3.36739 6.5 3.24021 6.44732 3.14645 6.35355C3.05268 6.25979 3 6.13261 3 6ZM3 8.5C3 8.36739 3.05268 8.24021 3.14645 8.14645C3.24021 8.05268 3.36739 8 3.5 8H8.5C8.63261 8 8.75979 8.05268 8.85355 8.14645C8.94732 8.24021 9 8.36739 9 8.5C9 8.63261 8.94732 8.75979 8.85355 8.85355C8.75979 8.94732 8.63261 9 8.5 9H3.5C3.36739 9 3.24021 8.94732 3.14645 8.85355C3.05268 8.75979 3 8.63261 3 8.5Z" fill="white" />
                </svg>
            </VerticalCenter>
            <VerticalCenter
                className={clsx(classes.profile, classes.footerItem, { "active": tab == 5 })}
                onClick={() => { history.push(`/Profile`) }}
            >
                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3 0.25H13C13.663 0.25 14.2989 0.513392 14.7678 0.982233C15.2366 1.45107 15.5 2.08696 15.5 2.75V15.25C15.5 15.913 15.2366 16.5489 14.7678 17.0178C14.2989 17.4866 13.663 17.75 13 17.75H3C2.33696 17.75 1.70107 17.4866 1.23223 17.0178C0.763392 16.5489 0.5 15.913 0.5 15.25V2.75C0.5 2.08696 0.763392 1.45107 1.23223 0.982233C1.70107 0.513392 2.33696 0.25 3 0.25ZM3 1.5C2.66848 1.5 2.35054 1.6317 2.11612 1.86612C1.8817 2.10054 1.75 2.41848 1.75 2.75V15.25C1.75 15.5815 1.8817 15.8995 2.11612 16.1339C2.35054 16.3683 2.66848 16.5 3 16.5H13C13.3315 16.5 13.6495 16.3683 13.8839 16.1339C14.1183 15.8995 14.25 15.5815 14.25 15.25V2.75C14.25 2.41848 14.1183 2.10054 13.8839 1.86612C13.6495 1.6317 13.3315 1.5 13 1.5H3Z" fill="white" />
                    <path d="M15.23 16.5C14.6088 14.9125 12.745 12.75 8.00002 12.75C3.25502 12.75 1.39127 14.9125 0.77002 16.5H15.23Z" fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8 11.5C8.49246 11.5 8.98009 11.403 9.43506 11.2145C9.89003 11.0261 10.3034 10.7499 10.6517 10.4017C10.9999 10.0534 11.2761 9.64003 11.4645 9.18506C11.653 8.73009 11.75 8.24246 11.75 7.75C11.75 7.25754 11.653 6.76991 11.4645 6.31494C11.2761 5.85997 10.9999 5.44657 10.6517 5.09835C10.3034 4.75013 9.89003 4.47391 9.43506 4.28545C8.98009 4.097 8.49246 4 8 4C7.00544 4 6.05161 4.39509 5.34835 5.09835C4.64509 5.80161 4.25 6.75544 4.25 7.75C4.25 8.74456 4.64509 9.69839 5.34835 10.4017C6.05161 11.1049 7.00544 11.5 8 11.5Z" fill="white" />
                </svg>
            </VerticalCenter>
        </footer>
    </>)
}