import React, { useContext, useState } from 'react'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useHistory, } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles';
import { Store } from "../../store"
import clsx from "clsx"

const useStyles = makeStyles({
    root: {
    },
});

export default ({ className, nextaction }) => {
    const history = useHistory()
    const classes = useStyles();
    const { globalState, setGlobalState } = useContext(Store)

    const setOpen = (open) => { setGlobalState({ type: "SET_NOTIFICATION", payload: { ...globalState.notification, open } }) }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    return (
        <Snackbar className={classes.snack} open={globalState.notification.open} autoHideDuration={6000} onClose={handleClose}>
            <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity={globalState.notification.severity}>
                {globalState.notification.message || "何かしらのエラーが発生しました"}
            </MuiAlert>
        </Snackbar>
    )
}