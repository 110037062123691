import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { VideoListItem } from '../../molecules'

const useStyles = makeStyles({
    root: {
        width: "100%",
        "&>*": {
            float: "left",
            width: "33.0%",
            margin: "0.166%",
        },
        "&>*:nth-child(n+13)": {
            display: "none"
        }
    }
});

export default (props) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            {props.children}
        </div>
    )
}