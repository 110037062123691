import axios from 'axios'
import URLJoin from '../URLJoin'
import AccessServer from '../AccessServer'

export default async (products, userId) => {
    try {
        const line_items = products.map((product) => {
            const lineItem = {
                variant_id: product.variantId,
                quantity: product.amount,
            }
            return lineItem
        })
        if (AccessServer()) {
            const uri = URLJoin(process.env.REACT_APP_URL_BACKEND, "/api/order")
            const param = {
                "line_items": line_items,
                "customer": {
                    "id": userId
                }
            }
            const { data } = await axios.post(uri, param)
            return { ...data }
        } else {
            const param = {
                "line_items": line_items,
                "customer": {
                    "id": userId
                }
            }
            console.log(param)
            const data = {
                "invoice_url": process.env.REACT_APP_URL_ORDER
            }
            console.log(data)
            return { ...data }
        }
    }
    catch (err) {
        throw new Error("Network Error")
    }
}