import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx"

const useStyles = makeStyles({
    root: {
        "height": "23px",
        "margin": "23px 0",
    },
    tab: {
        "float": "left",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "bold",
        "fontSize": "16px",
        "lineHeight": "23px",
        "color": "#C4C4C4",
        "&.active": {
            "color": "#000000",
        },
        "&:nth-child(n + 2)": {
            "marginLeft": "30px",
        },
    }
});

export default ({ tab, setTab, className, ...others }) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, className)} {...others}>
            <span
                className={clsx(classes.tab, { "active": (tab == 0) })}
                onClick={() => { setTab(0) }}
            >Shop</span>
            <span
                className={clsx(classes.tab, { "active": (tab == 1) })}
                onClick={() => { setTab(1) }}
            >Cart</span>
            <span
                className={clsx(classes.tab, { "active": (tab == 2) })}
                onClick={() => { setTab(2) }}
            >Payment</span>
        </div>
    )
}