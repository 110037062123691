import React, { useContext, useState } from 'react'
import Button from '../atoms/Button/Button'
import TextField from '../atoms/TextField/TextField'
import InputPassword from '../molecules/InputPassword'
import InputMail from '../molecules/InputMail'
import signUp from "../../actions/Account/signUp"
import { useHistory, } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles';
import { Store } from "../../store"
import clsx from "clsx"

const useStyles = makeStyles({
    root: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        padding: '0 20px',
        height: "100%",
    },
    underline: {
        margin: "0 -20px 0 -20px"
    },
    title: {
        margin: "29px 27px 39px"
    },
    item: {
        width: "100%",
        '& > div': {
            height: "30px"
        },
    },
    bottom: {
        textAlign: "center",
        padding: "21px 19px",
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0
    },
    button: {
        position: "absolute",
        bottom: "0",
        left: "0",
        // bottom: "0",
    }
});

export default ({ className, nextaction }) => {
    const history = useHistory()
    const classes = useStyles();
    const { globalState, setGlobalState } = useContext(Store)
    const setUserName = (userName) => {
        setGlobalState({ type: "SET_SIGNUP_USERNAME", payload: { userName } })
    }
    const setEmail = (email) => {
        setGlobalState({ type: "SET_SIGNUP_EMAIL", payload: { email } })
    }
    const [password1, __setPassword1] = useState("")
    const [password2, __setPassword2] = useState("")
    const setPassword1 = (password) => {
        __setPassword1(password)
        if (password == password2)
            setGlobalState({ type: "SET_SIGNUP_PASSWORD", payload: { password } })
        else
            setGlobalState({ type: "SET_SIGNUP_PASSWORD", payload: { password: "" } })
    }
    const setPassword2 = (password) => {
        __setPassword2(password)
        if (password == password1)
            setGlobalState({ type: "SET_SIGNUP_PASSWORD", payload: { password } })
        else
            setGlobalState({ type: "SET_SIGNUP_PASSWORD", payload: { password: "" } })
    }
    const loading = () => { setGlobalState({ type: "SET_LOADING", payload: { loading: true } }) }
    const loadDone = () => { setGlobalState({ type: "SET_LOADING", payload: { loading: false } }) }
    const setNotify = (severity, message, open) => { console.log(setNotify); setGlobalState({ type: "SET_NOTIFICATION", payload: { severity, message, open } }) }
    const __signUp = async () => {
        try {
            loading()
            if (globalState.signUpEmail === "") {
                console.log("no mail address")
                return
            }
            if (globalState.signUpPassword === "") {
                console.log("password doesn't match")
                return
            }
            const { token } = await signUp(globalState.signUpUserName, globalState.signUpEmail, globalState.signUpPassword)
            setGlobalState({ type: "SET_TOKEN", payload: { token: token } })
            const isFunction = (obj) => { return typeof (obj) == "function" };
            setNotify("success", "ユーザー登録に成功しました", true)
            if (isFunction(nextaction)) nextaction()
            return
        } catch (e) {
            setNotify("error", "ユーザー登録に失敗しました", true)
        } finally {
            loadDone()
        }
    }
    return (
        <div className={clsx(classes.root, className)}>
            <div className={classes.title}>ユーザー登録する</div>
            <hr class={classes.underline} />
            <TextField label="ユーザー名" className={classes.item} value={globalState.signUpUserName} onChange={setUserName} />
            <InputMail label="メールアドレス " className={classes.item} value={globalState.signUpEmail} onChange={setEmail} />
            <InputPassword label="パスワード" className={classes.item} value={password1} onChange={setPassword1} />
            <InputPassword label="パスワード(確認用)" className={classes.item} value={password2} onChange={setPassword2} />
            <Button fullWidth variant="contained" color="primary" className={classes.button} onClick={__signUp}>次へ</Button>
        </div>
    )
}