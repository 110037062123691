import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Text } from "../../atoms"
import clsx from "clsx"
import { Button } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        "width": "100%",
        "background": "#FFFFFF",
        "boxShadow": "0px 4px 7px rgba(0, 0, 0, 0.15)",
        "position": "relative",
    },
    imageWrapper: {
        "position": "relative",
        "width": "100%",
        "&:before": {
            "content": "\"\"",
            "display": "block",
            "paddingTop": "50%"
        }
    },
    image: {
        "position": "absolute",
        "object-fit": "cover",
        "width": "100%",
        "height": "100%",
        "top": "0",
        "left": "0",
        "bottom": "0",
        "right": "0"
    },
    name: {
        "display": "block",
        "marginTop": "19px",
        "marginLeft": "21px",
        "marginRight": "21px",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "normal",
        "fontSize": "10px",
        "lineHeight": "14px",
        "color": "#000000"
    },
    priceWrapper: {
        height: "71px",
        "&>span": { display: "block" },
    },
    price: {
        "float": "left",
        "marginLeft": "21px",
        "marginTop": "29px",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "normal",
        "fontSize": "16px",
        "lineHeight": "23px",
        "color": "#000000",
    },
    percent: {
        "float": "left",
        "marginLeft": "21px",
        "marginTop": "33px",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "normal",
        "fontSize": "12px",
        "lineHeight": "17px",
        "color": "#000000",
    },
    button: {
        "marginRight": "21px",
        "marginTop": "32px",
        "float": "right",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "bold",
        "fontSize": "14px",
        "lineHeight": "20px",
        "textAlign": "right",
        "color": "#000000"
    },
});

export default ({ name, imgSrc, price, discountedPrice, className, ...others }) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, className)} {...others}>
            {/* <div> */}
            <div className={classes.imageWrapper}>
                <img className={classes.image} src={imgSrc} />
            </div>
            <Text className={classes.name}>{name}</Text>
            <div className={classes.priceWrapper}>
                {
                    !!price ? !!discountedPrice ?
                        <>
                            <Text className={classes.price}>¥{price || 0}</Text>
                            <Text className={classes.percent}>{100 - (discountedPrice / price * 100)}% off</Text>
                        </> : <>
                            <Text className={classes.discountedPrice}>¥{discountedPrice || 0}</Text>
                        </> : <>
                            <Text className={classes.discountedPrice}>¥{price || 0}</Text>
                        </>
                }
                <span className={classes.button}>Get now</span>
            </div>
            {/* </div> */}
        </div>
    )
}