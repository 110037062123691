import axios from 'axios'
import random from "./Random"

// export { default as getVideo } from './getVideo';
// export { default as addVideo } from './addVideo';

export const getVideoCampain = async (videoId) => {

    const video = {
        imgSrc: "https://www.durianhunter.com/wp-content/uploads/2017/08/IMG_0653-556x788.jpg",
        videoUploader: "Brand Name",
        title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed consectetur adipiscing elit, sed ipsum dolor sit amet, consectetur adipiscing elit, sed consectetur adipiscing elit, sed",
        views: 1234567,
        id: 1000,
    }
    const data = {
        name: "静岡県のお土産キャンペーン",
        videos: [
            { ...video },
            { ...video },
            { ...video },
        ]
    }
    try {
        return data
    }
    catch (err) {
    }
}


export const getVideoTrend = async (videoId) => {

    const video = {
        imgSrc: "https://www.durianhunter.com/wp-content/uploads/2017/08/IMG_0653-556x788.jpg",
        hashTag: "ハッシュタグ",
        title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed consectetur adipiscing elit, sed ipsum dolor sit amet, consectetur adipiscing elit, sed consectetur adipiscing elit, sed",
        views: 1234567,
        id: 1000,
    }
    const data = {
        name: "静岡県のお土産キャンペーン",
        videos: [
            { ...video },
            { ...video },
            { ...video },
            { ...video },
            { ...video },
            { ...video },
            { ...video },
            { ...video },
        ]
    }
    try {
        return data
    }
    catch (err) {
    }
}

export const getVideoPopular = async (videoId) => {

    const video = {
        imgSrc: "https://www.durianhunter.com/wp-content/uploads/2017/08/IMG_0653-556x788.jpg",
        videoUploader: "videoUploader",
        title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed consectetur adipiscing elit, sed ipsum dolor sit amet, consectetur adipiscing elit, sed consectetur adipiscing elit, sed",
        views: 1234567,
        id: 1000,
    }
    const data = {
        name: "静岡県のお土産キャンペーン",
        videos: [
            { ...video },
            { ...video },
            { ...video },
            { ...video },
            { ...video },
            { ...video },
            { ...video },
            { ...video },
            { ...video },
            { ...video },
        ]
    }
    try {
        return data
    }
    catch (err) {
    }
}

export const getVideoNewProduct = async (videoId) => {

    const video = {
        imgSrc: "https://www.durianhunter.com/wp-content/uploads/2017/08/IMG_0653-556x788.jpg",
        videoUploader: "videoUploader",
        title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed consectetur adipiscing elit, sed ipsum dolor sit amet, consectetur adipiscing elit, sed consectetur adipiscing elit, sed",
        views: 1234567,
        id: 1000,
    }
    const data = {
        name: "静岡県のお土産キャンペーン",
        videos: [
            { ...video },
            { ...video },
            { ...video },
            { ...video },
            { ...video },
            { ...video },
            { ...video },
            { ...video },
            { ...video },
            { ...video },
        ]
    }
    try {
        return data
    }
    catch (err) {
    }
}

export const getVideoProduct = async (videoId) => {
    const video = {
        imgSrc: "https://www.durianhunter.com/wp-content/uploads/2017/08/IMG_0653-556x788.jpg",
        videoUploader: "videoUploader",
        title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed consectetur adipiscing elit, sed ipsum dolor sit amet, consectetur adipiscing elit, sed consectetur adipiscing elit, sed",
        views: 1234567,
        id: 1000,
    }
    const data = {
        videos: [
            { ...video },
            { ...video },
            { ...video },
            { ...video },
            { ...video },
            { ...video },
            { ...video },
            { ...video },
            { ...video },
            { ...video },
            { ...video },
            { ...video },
            { ...video },
            { ...video },
            { ...video },
            { ...video },
            { ...video },
            { ...video },
        ]
    }
    for (let i in data.videos) {
        data.videos[i].id = random()
    }
    try {
        return data
    }
    catch (err) {
    }
}

export const getVideoProfile = async (userId, key) => {

    const video = {
        imgSrc: "https://www.durianhunter.com/wp-content/uploads/2017/08/IMG_0653-556x788.jpg",
        videoUploader: "Brand Name",
        title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed consectetur adipiscing elit, sed ipsum dolor sit amet, consectetur adipiscing elit, sed consectetur adipiscing elit, sed",
        views: 1234567,
        id: 1000,
    }
    const data = {
        name: "静岡県のお土産キャンペーン",
        videos: [
            { ...video },
            { ...video },
            { ...video },
            { ...video },
            { ...video },
            { ...video },
            { ...video },
            { ...video },
        ]
    }
    try {
        return data
    }
    catch (err) {
    }
}

export const getVideoProfile3x4 = async (userId, key) => {

    const video = {
        imgSrc: "https://www.durianhunter.com/wp-content/uploads/2017/08/IMG_0653-556x788.jpg",
        videoUploader: "Brand Name",
        title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed consectetur adipiscing elit, sed ipsum dolor sit amet, consectetur adipiscing elit, sed consectetur adipiscing elit, sed",
        views: 1234567,
        id: 1000,
    }
    const data = {
        videos: [
            { ...video },
            { ...video },
            { ...video },
            { ...video },
            { ...video },
            { ...video },
            { ...video },
            { ...video },
            { ...video },
            { ...video },
            { ...video },
            { ...video },
        ]
    }
    try {
        return data
    }
    catch (err) {
    }
}