import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx"
import { Text } from "../atoms"
import { useHistory, } from "react-router-dom"

const useStyles = makeStyles({
    root: {
        width: "100%",
        height: "96px",
        overflow: "hidden",
        "&.big": {
            height: "110px",
        }
    },
    imagewrapper: {
        float: "left",
        width: "70px",
        height: "100%",
        ".big &": {
            width: "110px",
        }
    },
    image: {
        width: "70px",
        height: "70px",
        marginTop: "13px",
        objectFit: "cover",
        ".big &": {
            marginTop: "0",
            width: "110px",
            height: "110px",
        }
    },
    textwrapper: {
        float: "left",
        width: "calc(100% - 13px - 70px)",
        height: "100%",
        marginLeft: "13px",
        ".big &": {
            width: "calc(100% - 22px - 110px)",
            marginLeft: "22px",
        }
    },
    brandName: {
        overflow: "hidden",
        display: "block",
        marginTop: "13px",
        height: "14px",
        width: "100%",
        fontSize: "10px",
        ".big &": {
            "marginTop": "0",
            "height": "17px",
            "fontFamily": "Noto Sans Japanese",
            "fontStyle": "normal",
            "fontWeight": "normal",
            "fontSize": "12px",
            "lineHeight": "17px",
            "color": "#C4C4C4"
        },
        ".white &": {
            "color": "#ffffff"
        },
    },
    name: {
        overflow: "hidden",
        display: "block",
        marginTop: "4px",
        height: "28px",
        width: "100%",
        fontSize: "10px",
        ".big &": {
            "height": "60px",
            "fontFamily": "Noto Sans Japanese",
            "fontStyle": "normal",
            "fontWeight": "normal",
            "fontSize": "14px",
            "lineHeight": "20px",
            "color": "#000000"
        },
        ".white &": {
            "color": "#ffffff"
        },
    },
    price: {
        overflow: "hidden",
        width: "100%",
        display: "block",
        marginTop: "6px",
        height: "14px",
        fontSize: "10px",
        ".big &": {
        },
        ".white &": {
            "color": "#ffffff"
        },
    },
});

export default ({ imageSize, name, brandName, price, imgSrc, color, linkTo, className, ...others }) => {
    const classes = useStyles();
    const history = useHistory()
    return (
        <div className={clsx(classes.root, className, { "big": imageSize == "big" }, { "white": color == "white" })} {...others} >
            <div className={clsx(classes.imagewrapper)}>
                <img src={imgSrc} alt="thumbnail" className={classes.image} />
            </div>
            <div className={classes.textwrapper}>
                <Text className={classes.brandName}>{brandName}</Text>
                <Text className={classes.name}>{name}</Text>
                {
                    price
                        ? <Text className={classes.price}>￥{price}</Text>
                        : <></>
                }
            </div>
        </div >
    )
}