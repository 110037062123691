import axios from 'axios'
import random from "./Random"

export const Search = async (keyword) => {
    const relatedProduct = {
        imgSrc: "https://www.durianhunter.com/wp-content/uploads/2017/08/IMG_0653-556x788.jpg",
        title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed consectetur adipiscing elit, sed ipsum dolor sit amet, consectetur adipiscing elit, sed consectetur adipiscing elit, sed",
    }
    const live = {
        title: "ローラーとLouisVuitton特別の夏LIVE",
        startTime: "1560000000",
        imgSrc: "https://www.durianhunter.com/wp-content/uploads/2017/08/IMG_0653-556x788.jpg"
    }
    const video = {
        imgSrc: "https://www.durianhunter.com/wp-content/uploads/2017/08/IMG_0653-556x788.jpg",
        hashTag: "ハッシュタグ",
        videoUploader: "Brand Name",
        title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed consectetur adipiscing elit, sed ipsum dolor sit amet, consectetur adipiscing elit, sed consectetur adipiscing elit, sed",
        views: 1234567,
        id: 1000,
        relatedProduct,
    }
    try {
        const param = { keyword }
        // const res = await axios.get(`${process.env.REACT_APP_URL_ADDRESS_ZIP}/${first}/${second}.json`);
        const data = { live: { ...live } }//ダミー
        data.def = {
            name: "静岡県のお土産キャンペーン",
            videos: [
                { ...video },
                { ...video },
                { ...video },
            ]
        }
        data.preference = {
            name: "おすすめ",
            videos: [
                { ...video },
                { ...video },
                { ...video },
            ]
        }
        return data;
    }
    catch (err) {
    }
}

export const SearchProducts = async (keyword) => {
    const product = {
        id: "100",
        imgSrc: "https://www.durianhunter.com/wp-content/uploads/2017/08/IMG_0653-556x788.jpg",
        name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed consectetur adipiscing elit, sed ipsum dolor sit amet, consectetur adipiscing elit, sed consectetur adipiscing elit, sed",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed consectetur adipiscing elit, sed ipsum dolor sit amet, consectetur adipiscing elit, sed consectetur adipiscing elit, sed",
        brandName: "brand!!",
        price: 1000
    }
    try {
        const param = { keyword }
        // const res = await axios.get(`${process.env.REACT_APP_URL_ADDRESS_ZIP}/${first}/${second}.json`);
        const data = [
            { ...product, id: random() },
            { ...product, id: random() },
            { ...product, id: random() },
            { ...product, id: random() },
            { ...product, id: random() },
            { ...product, id: random() },
            { ...product, id: random() },
            { ...product, id: random() },
        ]
        console.log(data)
        return data;
    }
    catch (err) {
    }
}