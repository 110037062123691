import axios from 'axios'
import { v4 as uuidv4 } from 'uuid';
import URLJoin from '../URLJoin'
import path from "path"

const option = { headers: { 'content-type': 'multipart/form-data', } }

function extractHostDomain(url) {
    var host_domain;
    if (url.indexOf("://") > -1) {
        return url.split('/')[0] + "//" + url.split('/')[2];
    } else {
        throw new Error("no url")
    }
    return -1
}

export default async (setLoadPercent, userId, file, title, description) => {
    try {
        setLoadPercent(10)
        const extname = path.extname(file.name)
        const videoId = uuidv4()
        const filename = videoId + extname

        // fileをアップロードするためのURLを作成するAPIへアクセス
        const params = {
            filename: filename,
            filetype: file.type
        }
        const uriGetPreSignedAPI = URLJoin(process.env.REACT_APP_URL_BACKEND, "/api/s3-token-url")
        const { data: { url: uriPreSigned, ...others } } = await axios.get(uriGetPreSignedAPI, { params })

        setLoadPercent(20)

        // s3へのアップロード
        const options = {
            headers: { 'Content-Type': file.type, },
            onUploadProgress: progressEvent => {
                setLoadPercent((progressEvent.loaded / file.size) * 70 + 20)
            }
        };
        const res2 = await axios.put(uriPreSigned, file, options);

        setLoadPercent(90)

        const uri = URLJoin(process.env.REACT_APP_URL_BACKEND, "/api/video/", videoId)
        const urlExtracted = extractHostDomain(uriPreSigned)
        const url = `${urlExtracted}/${filename}` //[WIP] 動画のURL
        const params2 = { videoId, userId, title, description, url }
        const data = await axios.post(uri, params2)
        setLoadPercent(100)
        return { ...data }
    }
    catch (err) {
        console.error(err)
        throw new Error("Network Error")
    }
}