import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx"
import { ProductListContainer, ProductListItem } from '../../molecules';
import { getProductsCampain } from "../../../actions/Product"

const useStyles = makeStyles({
    root: {
    },
});

export default ({ className, ...others }) => {
    const classes = useStyles();
    const [products, setProducts] = useState([])
    const getData = async () => {
        const data = await getProductsCampain()
        setProducts(data)
    }
    useEffect(() => {
        getData()
        return () => { }
    }, [])
    return (
        <ProductListContainer>
            {products.map(({ id, name, brandName, price, imgSrc, }) => {
                return (
                    <ProductListItem price={price} name={name} brandName={brandName} imgSrc={imgSrc} linkTo={`/Product/${id}`} key={id} id={id} />
                )
            })}
        </ProductListContainer>
    )
}