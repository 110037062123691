import axios from 'axios'
import random from "./Random"

const initialProduct = {
    imgSrc: "https://www.durianhunter.com/wp-content/uploads/2017/08/IMG_0653-556x788.jpg",
    price: 110,
    discountedPrice: 100,
    brandName: "Brand Name",
    name: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sedpsum dolor sit amet",
}

const product = (productId) => {
    if (!productId)
        return { ...initialProduct, id: random() }
    else
        return { ...initialProduct, id: productId }
}

export const getProduct = async (productId) => {
    try {
        return product(productId)
    }
    catch (err) {
    }
}

export const getProductsCampain = async () => {
    try {
        const products = [
            product(),
            product(),
            product(),
            product(),
            product(),
            product(),
            product(),
            product(),
        ]
        return products
    }
    catch (err) {
    }
}