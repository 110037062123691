import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Button } from "../comoponents/atoms"
import VideoListProfile from '../comoponents/organisms/VideoListProfile';
import VideoListProfile3x4 from '../comoponents/organisms/VideoListProfile3x4';
import Introduction from '../comoponents/organisms/Introduction';
import { TabView } from '../comoponents/molecules/';
import GridOnIcon from '@material-ui/icons/GridOn';
import Layout from '../comoponents/templates/Layout'
import clsx from "clsx"

const useStyles = makeStyles({
    root: {
    },
    introduction: {
    },
    tab: {
        width: "100%",
        marginTop: "24px",
    },
    tabItem: {
        color: "#5e5e5e",
        "&.active": {
            color: "#ffffff"
        }
    }
});

export default ({ match }) => {
    const [tab, setTab] = useState(0)
    console.log(match)
    const userId = match.params.id || 0
    const classes = useStyles();
    return (
        <Layout className={classes.root}>
            <Introduction
                userId={userId}
                className={classes.introduction}
            />
            <div className={classes.tab}>
                <Button className={clsx(classes.tabItem, { active: tab == 0 })} onClick={() => setTab(0)}>リスト</Button>
                <Button className={clsx(classes.tabItem, { active: tab == 1 })} onClick={() => setTab(1)}>動画</Button>
                <Button className={clsx(classes.tabItem, { active: tab == 2 })} onClick={() => setTab(2)}>広告収益</Button>
            </div>
            {tab < 2 ? tab !== 1 ?
                <>
                    <TabView
                        tab1Icon={<GridOnIcon />}
                        tab2Icon={<GridOnIcon />}
                        tab1Content={<>
                            <VideoListProfile3x4 />
                        </>}
                        tab2Content={<>comming soon...</>}
                    />
                </>
                :
                <>
                    {/* リスト */}
                    <VideoListProfile userId={userId} key="1" />
                    <VideoListProfile userId={userId} key="2" />
                    <VideoListProfile userId={userId} key="3" />
                </>
                :
                <>
                    comming soon...
                </>
            }
        </Layout>
    )
}