import React from 'react'
import FormSignUp from "../comoponents/organisms/FormSignUp"
import Layout from '../comoponents/templates/Layout'

export default () => {
    return (
        <Layout>
            <FormSignUp />
        </Layout>
    )
}