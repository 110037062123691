import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';

const useStyles = makeStyles((theme) => ({
    content: {
        display: "block",
        padding: "0",
        margin: "0",
        width: "100%",
    },
    popover: {
        // display: "block",
        padding: "0",
        margin: "0",
        width: "100%",
        "& > div:nth-child(3)": {
            maxWidth: "",
            width: "100%"
        }
    },
}));

export default function SimplePopover({ children, popoverContent, ...other }) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div {...other}>
            <span className={classes.content} aria-describedby={id} variant="contained" color="primary" onClick={handleClick}>
                {children}
            </span>
            <Popover
                className={classes.popover}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {popoverContent}
            </Popover>
        </div>
    );
}