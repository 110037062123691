import axios from 'axios'
import URLJoin from '../URLJoin'
import AccessServer from '../AccessServer'

export default async (userName, email, password) => {
    try {
        if (AccessServer()) {
            const param = {
                "user": {
                    "username": userName,
                    "password": password,
                    "customer": {
                        "email": email,
                        "phone": "+15142546022",
                        "verified_email": true,
                        "addresses": []
                    },
                    "option": null
                }
            }
            const uri = URLJoin(process.env.REACT_APP_URL_BACKEND, "/api/user")
            const response = await axios.post(uri, param)
            console.log(JSON.stringify(param), response)
            const { data: { token: token, ...res }, ...other } = response
            console.log(token, other, res)
            return { token }
        } else {
            const param = { userName, email, password }
            const data = { token: "LQwDde6x8eV4ROOCOdSW" }//ダミー
            return data;
        }
    }
    catch (err) {
        return { error: true, payload: err }
    }
}