import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx"
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles({
    root: {
        "background": "#606060",
        "borderRadius": "30px",
        "height": "40px"
    },
    icon: {
        float: "left",
        margin: "11px 0 11px 20px",
        height: "17.5px",
        width: "17.5px",
    },
    input: {
        width: "calc(100% - 70px)",
        "height": "40px",
        "padding": 0,
        "border": 0,
        "background": "transparent",
        "margin": "0 0 0 10px",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "normal",
        "fontSize": "10px",
        "lineHeight": "14px",
        "color": "#ffffff",
        "&:focus": {
            outline: 0
        },
        // place holder の色
        "&::placeholder": {
            "fontFamily": "Noto Sans Japanese",
            "fontStyle": "normal",
            "fontWeight": "normal",
            "fontSize": "10px",
            "lineHeight": "14px",
            "color": "#979797",
        },
        // place holder の色 for IE
        "&:-ms-input-placeholder": {
            "fontFamily": "Noto Sans Japanese",
            "fontStyle": "normal",
            "fontWeight": "normal",
            "fontSize": "10px",
            "lineHeight": "14px",
            "color": "#979797",
        },
        // place holder の色 for Edge
        "&::-ms-input-placeholder": {
            "fontFamily": "Noto Sans Japanese",
            "fontStyle": "normal",
            "fontWeight": "normal",
            "fontSize": "10px",
            "lineHeight": "14px",
            "color": "#979797",
        },
    },
});

export default ({ value, setValue, className, ...others }) => {
    const classes = useStyles();
    // const [value, setValue] = useState(value || "")
    // const changeValue = v => { setCount(v); if (!!setValue) setValue(v) }
    const handleChange = e => { setValue(e.target.value) }
    return (
        <div className={clsx(classes.root, className)} {...others}>
            <svg className={classes.icon} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.5 11H11.71L11.43 10.73C12.4439 9.55402 13.0011 8.0527 13 6.5C13 5.21442 12.6188 3.95772 11.9046 2.8888C11.1903 1.81988 10.1752 0.986755 8.98744 0.494786C7.79972 0.00281635 6.49279 -0.125905 5.23191 0.124899C3.97104 0.375703 2.81285 0.994767 1.90381 1.90381C0.994767 2.81285 0.375703 3.97104 0.124899 5.23191C-0.125905 6.49279 0.00281635 7.79972 0.494786 8.98744C0.986755 10.1752 1.81988 11.1903 2.8888 11.9046C3.95772 12.6188 5.21442 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z" fill="white" />
            </svg>
            <input className={classes.input} value={value} onChange={handleChange} placeholder="キーワードを入力" />
        </div>
    )
} 