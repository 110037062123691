import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '../atoms'

const useStyles = makeStyles({
    root: {
        width: "100vw",
    },
    overlaycontainer: {
        marginTop: "22px",
        position: "relative",
        width: "100%",
        height: "0",
        paddingBottom: "75%",
        overflow: "hidden"
    },
    overlayimage: {
        display: "block",
        width: "100%",
        height: "auto",
    },
    overlayContent: {
        position: "absolute",
        top: "18px",
        left: "29px",
        borderRadius: "14px",
        background: "#FFFFFF",
        color: "#000000",
        padding: "1px 12px",
        fontSize: "14px",
        textAlign: "center",
    },
    title: {
        margin: "15px 73px 22px 30px",
        "width": "calc(100% - 73px - 30px)",
        "height": "20px",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "bold",
        "fontSize": "14px",
        "lineHeight": "20px",
        "color": "#FFFFFF"
    },
    startTime: {
        width: "100%"
    },
    image: {
        width: "100%"
    },
    button: {
        margin: "15px 15px 15px 15px",
        display: "block",
        width: "calc(100% - 30px) !important"
    }
});
export default function ({ title, startTime, imgSrc }) {
    const classes = useStyles();
    console.log(startTime)
    const dateTime = new Date(startTime * 1000);
    const date = dateTime.toLocaleDateString('ja-JP').slice(5)
    const time = dateTime.toLocaleTimeString('ja-JP')
    return (
        <div className={classes.root}>
            <div className={classes.title}>{title}</div>
            <div className={classes.overlaycontainer}>
                <img src={imgSrc} alt="Avatar" className={classes.overlayimage} />
                <div className={classes.overlayContent}>{date}{time}</div>
            </div>
            <Button fullWidth variant="contained" color="primary" className={classes.button}>Set Reminder</Button>
        </div>
    )
}

