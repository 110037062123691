import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx"

const useStyles = makeStyles({
    root: {
        "&>*": {
            float: "left",
            width: "25%",
            margin: "20px 0 20px 0"
        },
        "&>*:nth-child(n+9)": {
            display: "none"
        }
    },
});

export default ({ children, className, ...others }) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, className)} {...others}>
            {children}
        </div>
    )
}