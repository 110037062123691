import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx"

const useStyles = makeStyles({
    root: {
        "display": "block",
        "overflow": "hidden",
        "width": "100%",
        "height": "90px",
        "margin": "0",
        "padding": "0",
        "textAlign": "left",
        "wordBreak": "normal",
        "whiteSpace": "normal",
    },
    image: {
        "float": "left",
        "width": "90px",
        "height": "90px",
        "margin": "0 10px 0 0",
        "display": "block",
        "objectFit": "cover",
        "borderRadius": "10px"
    },
    textwrapper: {
        "float": "left",
        "margin": "0 0 0 0",
        "width": "calc(100% - 100px)",
        "height": "90px"
    },
    title: {
        "height": "34px",
        "width": "100%",
        "overflowX": "hidden",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "normal",
        "fontSize": "12px",
        "lineHeight": "17px",
        "color": "#FFFFFF",
    },
    uploader: {
        "width": "100%",
        "height": "14px",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "normal",
        "fontSize": "10px",
        "lineHeight": "14px",
        "color": "#979797",
        "flex": "none",
        "order": "1",
        "alignSelf": "flex-start",
        "flexGrow": "0",
        "margin": "0px 10px 0 0",
    },
    views: {
        "width": "100%",
        "height": "14px",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "normal",
        "fontSize": "10px",
        "lineHeight": "14px",
        "color": "#979797",
        "flex": "none",
        "order": "1",
        "alignSelf": "flex-start",
        "flexGrow": "0",
        "margin": "0",
    },
});

export default ({ title, videoUploader, views, imgSrc, size, HashTag, type, className, ...others }) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, className)} {...others}>
            <img src={imgSrc} alt="thumbnail" className={classes.image} />
            <div className={classes.textwrapper}>
                <div className={classes.title}>{title}</div>
                <div className={classes.uploader}>{videoUploader}</div>
                <div className={classes.views}>{views} views</div>
            </div>
            {/* </div> */}
        </div>
    )
}