import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx"

const useStyles = makeStyles({
    root: {
        "display": "block",
        "width": "121px",
        "margin": "0px",
        "padding": "3px",
        "textAlign": "left",
        "wordBreak": "normal",
        "whiteSpace": "normal"
    },
    image: {
        "borderRadius": "10px",
        "display": "block",
        "width": "100%",
        "height": "auto"
    },
    title: {
        "height": "42px",
        "overflowX": "hidden",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "normal",
        "fontSize": "10px",
        "lineHeight": "14px",
        "color": "#ffffff",
    },
    uploader: {
        "fontSize": "10px",
        "color": "#979797"
    },
    views: {
        "fontSize": "10px",
        "color": "#979797"
    },
});

export default ({ title, videoUploader, views, imgSrc, size, HashTag, type, className, ...others }) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, className)} {...others}>

            {/* <div className={Style.midium}> */}
            <img src={imgSrc} alt="thumbnail" className={classes.image} />
            <div className={classes.title}>{title}</div>
            <div className={classes.uploader}>{videoUploader}</div>
            <div className={classes.views}>{views} views</div>
            {/* </div> */}
        </div>
    )
}