import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx"
import { Text } from "../../atoms"

const useStyles = makeStyles({
    root: {
    },
    image: {
        width: "100%"
    },
    name: {
        display: "block",
        width: "calc(100% - 60px)",
        height: "40px",
        margin: "19px 30px 0 30px",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "normal",
        "fontSize": "14px",
        "lineHeight": "20px",
        "color": "#000000"
    },
    brandName: {
        display: "block",
        width: "calc(100% - 60px)",
        height: "17px",
        margin: "15px 30px 18px 30px",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "normal",
        "fontSize": "12px",
        "lineHeight": "17px",
        "color": "#C4C4C4"

    },
});

export default ({ imgSrc, name, brandName, className, ...others }) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, className)} {...others}>
            <img className={classes.image} src={imgSrc} />
            <Text className={classes.name}>{name}</Text>
            <Text className={classes.brandName}>{brandName}</Text>
        </div>
    )
}