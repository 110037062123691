import React from 'react'
import { Search, SearchResult1, SearchResult2, SearchResult3 } from "../comoponents/organisms"
import { makeStyles } from '@material-ui/core/styles';
import Layout from '../comoponents/templates/Layout'
import clsx from "clsx"

const useStyles = makeStyles({
    root: {
    },
});

export default ({ className, ...others }) => {
    const classes = useStyles();
    return (
        <Layout>
            <Search />
            <SearchResult1 />
            <SearchResult2 />
            <SearchResult3 />
        </Layout>
    )
}