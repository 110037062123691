import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx"
import { VideoLive, } from '../molecules'
import { useContext, useEffect } from 'react'
import { Store } from "../../store"

const useStyles = makeStyles({
    root: {
    },
});

export default ({ className, ...others }) => {
    const classes = useStyles();
    const { globalState, setGlobalState } = useContext(Store)

    if (globalState.searchResult.def.videos.length === undefined || globalState.searchResult.def.videos.length == 0)
        return (<></>)

    return (
        <>
            <VideoLive
                className={clsx(classes.root, className)} {...others}
                title={globalState.searchResult.live.title}
                startTime={globalState.searchResult.live.startTime}
                imgSrc={globalState.searchResult.live.imgSrc}
            />
        </>
    )
}