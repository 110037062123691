import React from 'react'
import { ProductGetNowCard } from "../../molecules"
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx"

const useStyles = makeStyles({
    root: {
        margin: "22px"
    },
});

export default ({ className, ...others }) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, className)} {...others}>
            <ProductGetNowCard
                name="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed"
                imgSrc="https://www.durianhunter.com/wp-content/uploads/2017/08/IMG_0653-556x788.jpg"
                price="100"
                discountedPrice="100"
            />
        </div>
    )
}