import React, { useContext, useState, useEffect } from 'react'
import FormSignIn from "./FormSignIn"
import FormSignUp from "./FormSignUp"
import Modal from "../molecules/Modal"
import SwipeableViews from "react-swipeable-views";
import { useHistory, } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles';
import { Store } from "../../store"

const useStyles = makeStyles({
    root: {
    },
    swipeItem: {
        height: "370px",
    },
});

export default ({ nextaction }) => {
    const history = useHistory()
    const classes = useStyles();
    const { globalState, setGlobalState } = useContext(Store)
    const [index, setIndex] = useState(0)
    const [open, setOpen] = useState(true)
    useEffect(() => {
        if (open) setIndex(0)
        return () => { }
    }, [open])
    const closeModal = () => { console.log("close modal"); setOpen(false) }
    return (
        <>
            <Modal open={!globalState.token} onClose={() => { setOpen(false) }} height={400} width={300}>
                <SwipeableViews index={index} onChangeIndex={setIndex}>
                    <FormSignIn className={classes.swipeItem} nextaction={closeModal} goToSignUp={() => { setIndex(1) }} />
                    <FormSignUp className={classes.swipeItem} nextaction={closeModal} />
                </SwipeableViews>
            </Modal>
        </>
    )
}