import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx"
import { useHistory, } from "react-router-dom"

const useStyles = makeStyles({
    root: {
    },
    text: {
        "margin": "35px 50px 0 50px",
        "width": "calc(100% - 100px)",
        "height": "46px",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "normal",
        "fontSize": "16px",
        "lineHeight": "23px",
        "textAlign": "center",
        "color": "#000000"
    },
    button: {
        "margin": "35px 12px 0 12px",
        "width": "calc(100% - 24px)",
        "height": "calc(52px - 17px -17px)",
        "background": "#000000",
        "textAlign": "center",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "normal",
        "fontSize": "12px",
        "lineHeight": "17px",
        "color": "#FFFFFF",
        "padding": "17px 0",
    },
});

export default ({ className, ...others }) => {
    const classes = useStyles();
    const history = useHistory();
    return (
        <div className={clsx(classes.root, className)} {...others}>
            <div className={classes.text}>Create an account to create lists and post videos with your profile</div>
            <div className={classes.button} onClick={() => history.push("/CreateAccount")}>Sign in to create a profile</div>
        </div>
    )
}