import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx"

const useStyles = makeStyles({
    root: {
        height: "100%",
        width: "100%",
        display: "table",
    },
    item: {
        display: "table-cell",
        verticalAlign: "middle",
        textAlign: "center",
        width: "100%",
        "& > *": {
            display: "inline-block",
        }
    },
});

export default ({ children, className, ...others }) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, className)} {...others}>
            <div className={classes.item}>
                {children}
            </div>
        </div>
    )
}