import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import clsx from "clsx"

const useStyles = makeStyles({
    root: {
        "position": "fixed",
        "zIndex": "20",
        "width": "100%",
        "top": "0",
        "left": "0",
        "height": "calc(100% - 56px)",
        "overflow": "hidden",
        "transition": "all 0.3s",
        "&.close": {
            "top": "100%",
            "height": "0"
        }
    },
    click: {
        "left": "0px",
        "background": "rgba(0, 0, 0, 0.8)",
        "zIndex": 0,
        "position": "absolute",
        width: "100%",
        height: "100%",
        top: '0',
        bottom: "0",
        left: "0",
        right: "0",
    },
    child: {
        "zIndex": 2,
        "position": "absolute",
        bottom: "0",
        left: "0",
        right: "0",
        "background": "#FFFFFF",
        "color": "black",
        "boxShadow": "0px 0px 10px rgba(0, 0, 0, 0.25)",
        "borderRadius": "15px",
        "padding": "15px",
    },
    container: {
        position: "relative",
        width: "100%",
        height: "100%",
    },
    xButton: {
        display: "none",
        "zIndex": "1",
        "&.enable": {
            display: "block",
            position: "absolute",
            top: "15px",
            right: "30px"
        }
    }
});

export default ({ open, onClose, closeButton, children, className, ...others }) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, { 'close': !open }, className)} {...others}>
            <div className={classes.click} onClick={onClose}></div>
            <div className={classes.child}>
                <div className={classes.container}>
                    <div className={clsx(classes.xButton, { 'enable': closeButton })} onClick={onClose}>
                        <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7809 1.219C12.8507 1.28867 12.9061 1.37143 12.9439 1.46255C12.9817 1.55367 13.0012 1.65135 13.0012 1.75C13.0012 1.84865 12.9817 1.94633 12.9439 2.03745C12.9061 2.12857 12.8507 2.21133 12.7809 2.281L2.28085 12.781C2.14002 12.9218 1.94901 13.0009 1.74985 13.0009C1.55069 13.0009 1.35968 12.9218 1.21885 12.781C1.07802 12.6402 0.998901 12.4492 0.998901 12.25C0.998901 12.0508 1.07802 11.8598 1.21885 11.719L11.7189 1.219C11.7885 1.14915 11.8713 1.09374 11.9624 1.05593C12.0535 1.01812 12.1512 0.998657 12.2499 0.998657C12.3485 0.998657 12.4462 1.01812 12.5373 1.05593C12.6284 1.09374 12.7112 1.14915 12.7809 1.219Z" fill="black" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.219 1.219C1.14915 1.28867 1.09374 1.37143 1.05593 1.46255C1.01812 1.55367 0.998657 1.65135 0.998657 1.75C0.998657 1.84865 1.01812 1.94633 1.05593 2.03745C1.09374 2.12857 1.14915 2.21133 1.219 2.281L11.719 12.781C11.8598 12.9218 12.0508 13.0009 12.25 13.0009C12.4492 13.0009 12.6402 12.9218 12.781 12.781C12.9218 12.6402 13.0009 12.4492 13.0009 12.25C13.0009 12.0508 12.9218 11.8598 12.781 11.719L2.281 1.219C2.21133 1.14915 2.12857 1.09374 2.03745 1.05593C1.94633 1.01812 1.84865 0.998657 1.75 0.998657C1.65135 0.998657 1.55367 1.01812 1.46255 1.05593C1.37143 1.09374 1.28867 1.14915 1.219 1.219Z" fill="black" />
                        </svg>
                    </div>
                    {children}
                </div>
            </div>
        </div>
    )
}