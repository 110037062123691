import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { VerticalCenter } from "../"
import { Text } from "../../atoms"
import clsx from "clsx"

const useStyles = makeStyles({
    root: {
        height: "100%",
    },
    icon: {
        height: "100%",
        width: "50px",
        float: "left"
    },
    text: {
        float: "left",
        textAline: "left",
        width: "calc(100% - 50px - 22px)",
        marginLeft: "22px",
        "& > *": {
            textAlign: "left"
        }
    },
    textNoIcon: {
        float: "left",
        textAline: "left",
        marginLeft: "72px",
        width: "calc(100% - 50px - 22px)",
        "& > *": {
            textAlign: "left"
        }
    },
});

export default ({ icon, text, className, children, ...others }) => {
    const classes = useStyles();
    if (icon)
        return (
            <div className={clsx(classes.root, className)} {...others}>
                <VerticalCenter className={classes.icon}>
                    <div>{icon}</div>
                </VerticalCenter>
                <VerticalCenter className={classes.text}>
                    <Text>
                        {children}
                    </Text>
                </VerticalCenter>
            </div>
        )
    return (
        <div className={clsx(classes.root, className)} {...others}>
            <VerticalCenter className={classes.textNoIcon}>
                <Text>
                    {children}
                </Text>
            </VerticalCenter>
        </div>
    )
}