import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';
import { VerticalCenter } from "../"

const useStyles = makeStyles({
    root: {
        width: "100%",
        height: "60px",
    },
    iconWrapper: {
        float: "left",
        width: "62px",
    },
    icon: {
        width: "38px",
        height: "38px",
        float: "left",
    },
    userNameWrapper: {
        margin: "0 0 0 20px",
        width: "auto",
    },
    userName: {
        float: "left",
    },
});

export default ({ userName, imgSrc }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <VerticalCenter className={classes.iconWrapper}>
                <div>
                    <Avatar className={classes.icon}>{!userName ? "H" : userName.slice(0, 1)}</Avatar>
                </div>
            </VerticalCenter>
            <VerticalCenter className={classes.userNameWrapper}>
                <div className={classes.userName}>{userName}</div>
            </VerticalCenter>
        </div>
    )
}