import React, { useState, useContext, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { AppearFromBottom, } from '../molecules'
import getVideoListList from "../../actions/Video/getVideoListList"
import addVideoToVideoList from "../../actions/Video/addVideoToVideoList"
import { Button } from '../atoms'
import clsx from "clsx"

const useStyles = makeStyles({
    root: {
        "&>*:nth-child(2)": {
            height: "471px"
        },
        "&>*:nth-child(2)>div": {
            height: "calc(100% - 30px)"
        }
    },
    header: {
        "margin": "33px",
        "width": "calc(100% - 66px)",
        "height": "23px",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "bold",
        "fontSize": "16px",
        "lineHeight": "23px",
        "color": "#000000",
    },
    contentsWrapper: {
        overflowY: "scroll",
        height: "300px",
        paddingBottom: "60px",
    },
    wrapper: {
        width: "calc(100% - 34px - 10px)",
        margin: "0 34px 0 10px",
        height: "44px",
    },
    text: {
        "float": "left",
        "width": "calc(100% - 80px)",
        "height": "20px",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "bold",
        "fontSize": "14px",
        "lineHeight": "20px",
        "color": "#000000",
    },
    saveButton: {
        "float": "left",
        "width": "70px",
        "height": "13.5px",
        "padding": "5.25px 0",
        "background": "#21EBA2",
        "borderRadius": "5px",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "bold",
        "fontSize": "10px",
        "lineHeight": "14px",
        "alignItems": "center",
        "textAlign": "center",
        "color": "#FFFFFF"
    },
    AddVideoListButton: {
        "position": "absolute",
        "bottom": "16px",
        "left": "0",
    },
});

export default ({ videoId, open, setOpen, className, ...others }) => {
    const classes = useStyles();
    const [videoListList, setVideoListList] = useState([])

    const __getVideoListList = async () => {
        try {
            const videoListList = await getVideoListList()
            setVideoListList(videoListList)
        } catch (e) {

        }
    }
    useEffect(() => {
        __getVideoListList()
        return () => { }
    }, [])

    return (
        <>
            <AppearFromBottom open={open} onClose={() => { setOpen(false) }} closeButton className={clsx(classes.root, className)} {...others}>
                <header className={classes.header}>
                    保存する
                </header>
                <div className={classes.contentsWrapper}>
                    {videoListList.map(({ name, id }) => {
                        return (
                            <div className={classes.wrapper} key={id}>
                                <div className={classes.text}>{name}</div>
                                <div
                                    className={classes.saveButton}
                                    onClick={() => { addVideoToVideoList(videoId, id) }}
                                >保存</div>
                            </div>
                        )
                    })}
                </div>
                <Button className={classes.AddVideoListButton} variant="contained" color="secondary" fullWidth
                    onClick={() => { }}>
                    Create New List
                </Button>
            </AppearFromBottom>
        </>
    )
}