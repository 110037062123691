import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx"
import { Text } from "../../atoms"

const useStyles = makeStyles({
    root: {
        width: "100%",
        height: "46px",
    },
    discountedPrice: {
        float: "left",
        marginLeft: "32px",
        marginTop: "13px",
        fontSize: "14px"
    },
    price: {
        "float": "left",
        "marginLeft": "9px",
        "marginTop": "17px",
        "textDecorationLine": "line-through",
        "fontSize": "11px",
        color: "#DDDDDD"
    },
    percent: {
        "float": "right",
        "marginRight": "26px",
        "marginTop": "13px",
    },
});

export default ({ price, discountedPrice, className, ...others }) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, className)} {...others}>
            {
                !!price ? !!discountedPrice ?
                    <>
                        <Text className={classes.discountedPrice}>¥{discountedPrice || 0}</Text>
                        <Text className={classes.price}>¥{price || 0}</Text>
                        <Text className={classes.percent}>{100 - (discountedPrice / price * 100)}% off</Text>
                    </> : <>
                        <Text className={classes.discountedPrice}>¥{discountedPrice || 0}</Text>
                    </> : <>
                        <Text className={classes.discountedPrice}>¥{price || 0}</Text>
                    </>
            }
        </div >
    )
}