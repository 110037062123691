import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx"
import { theme } from '../templates/ColorProvider'
import { FullSizeModal } from '../molecules';

const useStyles = makeStyles({
    root: {
        // position: "relative",
        background: "#181818",
        "width": "100%",
    },
    header: {
        "width": "100%",
        "height": "79px",
        "borderBottom": "0.5px solid #FFFFFF",
        "marginBottom": "8px",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "bold",
        "fontSize": "16px",
        "lineHeight": "23px",
        "color": "#FFFFFF",
    },
    headerIcon: {
        float: "left",
        margin: "32px 0 0 18px",
    },
    headerText: {
        "float": "left",
        "width": "calc(100% - 100px)",
        "height": "22px",
        "padding": 0,
        "margin": "32px 0 0 32px",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "bold",
        "fontSize": "16px",
        "lineHeight": "23px",
        "color": "#FFFFFF",
    },
    textArea: {
        backgroundColor: "transparent",
        height: "calc(100% - 19px - 19px - 79px - 8px - 52px)",
        width: "calc(100% - 32px - 32px)",
        margin: "19px 32px",
        padding: "0",
        border: "solid 0px",
        outline: "none",
        "color": "#FFFFFF",
        "-moz-appearance": "textfield", // firefox と IEでスピンボタンを消す
        "&::-webkit-outer-spin-button , &::-webkit-inner-spin-button": { // chromeでスピンボタンを消す。
            "-webkit-appearance": "none",
            margin: 0
        },
        "&::placeholder": {
            color: "#C4C4C4"
        },
    },
    button: {
        "height": "35px",
        "padding": "17px 0 0 0",
        "textAlign": "center",
        "position": "absolute",
        "bottom": "0",
        "left": "0",
        "right": "0",
        "zIndex": "1",
        "background": theme.palette.secondary.main,
        "color": theme.palette.secondary.contrastText,
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "bold",
        "fontSize": "14px",
        "lineHeight": "20px",
        "alignItems": "center",
        "textAlign": "center",
        "color": "#FFFFFF"
    }
});

export default ({ open, setOpen, description, setDescription, className, ...others }) => {
    const classes = useStyles();
    const ArrowRight = ({ className, ...others }) => {
        return (
            <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.0002 11V13H8.00016L13.5002 18.5L12.0802 19.92L4.16016 12L12.0802 4.08002L13.5002 5.50002L8.00016 11H20.0002Z" fill="white" />
            </svg>
        )
    }
    const close = () => { setOpen(false) }
    return (
        <FullSizeModal open={open} setOpen={setOpen} className={clsx(classes.root, className)} {...others}>
            <div className={classes.header} onClick={close}>
                <ArrowRight className={classes.headerIcon} />
                <span className={classes.headerText}>write description</span>
            </div>
            <textarea value={description} onChange={(e) => { setDescription(e.target.value) }} className={classes.textArea} placeholder="Type in your description here..." />
            <div className={classes.button} onClick={close}>Add description</div>
        </FullSizeModal>
    )
}