import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/Home';
import SearchIcon from '@material-ui/icons/Search';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

const useStyles = makeStyles({
    root: {
        overflow: "hidden",
        width: "100%",
        zIndex: "1",
        position: "fixed",
        top: "0",
        left: "0",
        background: "#FF00FF",
    },
    fixed: {
        zIndex: "2",
        width: "100%",
        color: "#000000",
        position: "absolute",
        top: "0",
        left: "0",
        display: "flex",
    },
    move: {
        zIndex: "1",
        width: "100%",
        // color: "#000000",
        position: "absolute",
        // top: "0",
        bottom: "0",
        left: "0",
        background: "-moz-linear-gradient(top,transparent, #FFFFFF)",
        background: "-webkit-linear-gradient(top,transparent, #FFFFFF)",
        background: "linear-gradient(to bottom,transparent, #FFFFFF)"
    },
    icons: {
        margin: "25px 20px auto auto"
    },
    title: {
        fontSize: "24px",
        margin: "calc(276px - 87px - 181px) 29px auto 29px"
    },
    image: {
        zIndex: "-1",
        width: "100%"
    }
});

export default ({ title, minHeight, maxHeight, imgSrc }) => {
    const classes = useStyles();
    const [height, setHeight] = useState(0);
    const [showTitle, setShowTitle] = useState({ "display": "hidden" });

    const scrollTop = () => {
        return Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
    };
    const onScroll = () => {
        const position = scrollTop();
        if (position <= maxHeight - minHeight) {
            setShowTitle({ "display": "hidden" })
            setHeight(maxHeight - position)
        }
        else {
            setShowTitle({ "display": "hidden" })
            setHeight(minHeight)
        }
        console.log(position)
    };

    useEffect(
        () => {
            document.addEventListener("scroll", onScroll);
            onScroll()
        },
        [],
    );
    return (
        <>
            <div className={classes.root} style={{ height: `${height}px` }}>
                <img src={imgSrc} className={classes.image} />
                <div className={classes.fixed} style={{ height: "50px" }}>
                    <div className={classes.icons} >
                        <HomeIcon />
                        <VideoCallIcon />
                        <SearchIcon />
                        <PermIdentityIcon />
                        <FavoriteIcon />
                        <ShoppingCartIcon />
                    </div>
                </div>
                <div className={classes.move} style={{ height: `${minHeight}px` }}>
                    <span className={classes.title} style={showTitle}>{title}</span>
                </div>
            </div>

            <div style={{ width: "100%", height: `${maxHeight}px` }}></div>
        </>
    )
}