import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Message } from "../comoponents/organisms"
import Layout from '../comoponents/templates/Layout'

const useStyles = makeStyles({
    root: {
    },
});

export default ({ className, ...others }) => {
    const classes = useStyles();
    return (
        <Layout>
            <Message />
        </Layout>
    )
}