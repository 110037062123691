import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx"
const useStyles = makeStyles({
    root: {
        "height": "56px"
    },
    icon: {
        float: "left",
        margin: "17px 0 17px 16px",
        height: "17.5px",
        width: "17.5px",
    },
    input: {
        "height": "16px",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "normal",
        "fontSize": "16px",
        "lineHeight": "16px",
        "color": "#979797",
        "flex": "none",
        "order": "1",
        "alignSelf": "center",
        "flexGrow": "0",
        "width": "calc(100% - 70px)",
        "height": "100%",
        "padding": 0,
        "border": 0,
        "background": "transparent",
        "margin": "0 0 0 10px",
        "&:focus": {
            outline: 0
        },
        // place holder の色
        "&::placeholder": {
            "fontFamily": "Noto Sans Japanese",
            "fontStyle": "normal",
            "fontWeight": "normal",
            "fontSize": "10px",
            "lineHeight": "14px",
            "color": "#979797",
        },
        // place holder の色 for IE
        "&:-ms-input-placeholder": {
            "fontFamily": "Noto Sans Japanese",
            "fontStyle": "normal",
            "fontWeight": "normal",
            "fontSize": "10px",
            "lineHeight": "14px",
            "color": "#979797",
        },
        // place holder の色 for Edge
        "&::-ms-input-placeholder": {
            "fontFamily": "Noto Sans Japanese",
            "fontStyle": "normal",
            "fontWeight": "normal",
            "fontSize": "10px",
            "lineHeight": "14px",
            "color": "#979797",
        },
    },
});

export default ({ value, setValue, className, ...others }) => {
    const classes = useStyles();
    const handleChange = e => { setValue(e.target.value) }
    return (
        <div className={clsx(classes.root, className)} {...others}>
            <svg className={classes.icon} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.5 14H14.71L14.43 13.73C15.4439 12.554 16.0011 11.0527 16 9.5C16 8.21442 15.6188 6.95772 14.9046 5.8888C14.1903 4.81988 13.1752 3.98676 11.9874 3.49479C10.7997 3.00282 9.49279 2.87409 8.23191 3.1249C6.97104 3.3757 5.81285 3.99477 4.90381 4.90381C3.99477 5.81285 3.3757 6.97104 3.1249 8.23191C2.87409 9.49279 3.00282 10.7997 3.49479 11.9874C3.98676 13.1752 4.81988 14.1903 5.8888 14.9046C6.95772 15.6188 8.21442 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z" fill="#C8C8C8" />
            </svg>
            <input className={classes.input} value={value} onChange={handleChange} placeholder="キーワードを入力" />
        </div>
    )
}