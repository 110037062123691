import React from 'react'
import { Footer } from '../organisms'
import Notification from "../organisms/Notification"
import viewportUnit from 'viewport-units-buggyfill';
import Loading from "../molecules/Loading"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    layout: {
        paddingBottom: "56px",
        display: "inline-block",
        width: "100%",
        background: "#181818",
        minHeight: "calc(100% - 56px)",
        height: "calc(100% - 56px)",
        color: "white",
        "&>*": {
            background: "#181818",
        }
    },
});
const Layout = ({ tab, children }) => {
    const classes = useStyles();
    viewportUnit.init({ force: true });
    return (<>
        <div className={classes.layout}>
            {children}
        </div>
        <Loading />
        <Notification />
        <Footer
            tab={tab}
        />
    </>)
}

export default Layout
