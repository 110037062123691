import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {

        overflowY: "scroll",
        whiteSpace: "nowrap",
    },
    item: {
        margin: "6px",
        display: "inline-block",
        overflowY: "auto",
    },
});


const HorizontalScroller = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            {props.children}
        </div>
    )
}
export default HorizontalScroller

export const HorizontalScrollerItem = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.item}>
            {props.children}
        </div>
    )
}