import React, { useContext, useState } from 'react'
import Button from '../atoms/Button/Button'
import TextField from '../atoms/TextField/TextField'
import InputPassword from '../molecules/InputPassword'
import InputMail from '../molecules/InputMail'
import signIn from "../../actions/Account/signIn"
import { useHistory, } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles';
import { Store } from "../../store"
import clsx from "clsx"
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles({
    root: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        padding: '0 20px',
        height: "100%",
    },
    underline: {
        margin: "0 -20px 0 -20px"
    },
    title: {
        margin: "29px 27px 39px"
    },
    item: {
        width: "100%",
        '& > div': {
            height: "30px"
        },
    },
    bottom: {
        textAlign: "center",
        padding: "21px 19px",
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0
    },
    buttonSignUp: {
        position: "absolute",
        bottom: "50px",
        left: "0",
    },
    button: {
        position: "absolute",
        bottom: "0",
        left: "0",
        // bottom: "0",
    },
    snack: {
        position: "fixed",
        bottom: "0",
        left: "0",
        right: "0",
    },
});

export default ({ className, nextaction, goToSignUp }) => {
    const history = useHistory()
    const classes = useStyles();
    const [error, setError] = useState(false)
    const { globalState, setGlobalState } = useContext(Store)
    const setUserName = (userName) => {
        setGlobalState({ type: "SET_SIGNUP_USERNAME", payload: { userName } })
    }
    const setEmail = (email) => {
        setGlobalState({ type: "SET_SIGNUP_EMAIL", payload: { email } })
    }
    const setPassword = password => {
        setGlobalState({ type: "SET_SIGNUP_PASSWORD", payload: { password } })
    }
    const isFunction = (obj) => { return typeof (obj) == "function" };
    const loading = () => { setGlobalState({ type: "SET_LOADING", payload: { loading: true } }) }
    const loadDone = () => { setGlobalState({ type: "SET_LOADING", payload: { loading: false } }) }
    const setNotify = (severity, message, open) => { console.log(setNotify); setGlobalState({ type: "SET_NOTIFICATION", payload: { severity, message, open } }) }

    const __signIn = async () => {
        try {
            loading()
            if (globalState.signUpUserName === "") {
                console.log("no user name")
                return
            }
            if (globalState.signUpPassword === "") {
                console.log("password doesn't exist")
                return
            }
            const { token: token } = await signIn(globalState.signUpUserName, globalState.signUpPassword)

            setGlobalState({ type: "SET_TOKEN", payload: { token: token } })
            setNotify("success", "ログインに成功しました", true)
            if (isFunction(nextaction)) nextaction()
            return
        } catch (e) {
            setNotify("error", "ログインに失敗しました", true)
        } finally {
            loadDone()
        }
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setError(false);
    };
    return (
        <div className={clsx(classes.root, className)}>
            <div className={classes.title}>ログイン</div>
            <hr class={classes.underline} />
            <TextField label="ユーザーネーム、または、メールアドレス" className={classes.item} value={globalState.signUpUserName} onChange={setUserName} />
            {/* <InputMail label="メールアドレス " className={classes.item} value={globalState.signUpEmail} onChange={setEmail} /> */}
            <InputPassword label="パスワード" className={classes.item} value={globalState.signUpPassword} onChange={setPassword} />
            <Button fullWidth variant="contained" color="primary" className={classes.buttonSignUp} onClick={() => { if (isFunction(goToSignUp)) goToSignUp() }}>アカウントを作る</Button>
            <Button fullWidth variant="contained" color="primary" className={classes.button} onClick={__signIn}>ログイン</Button>
        </div >
    )
}