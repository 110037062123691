import React from 'react'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const innerTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#262626",
            contrastText: '#fff',
        },
        secondary: {
            main: "#21E69E",
            contrastText: '#fff',
        },
        background: {
            default: "#181818",
        },
        text: {
            primary: "#ffffff",
            secondary: "#ffffff",
            disable: "#424242"
        }
    },
    typography: {
        button: {
            textTransform: 'none'
        }
    },
});
export const theme = innerTheme

export default ({ children, ...others }) => {
    return (
        <ThemeProvider theme={innerTheme}>
            {children}
        </ThemeProvider>
    )
}