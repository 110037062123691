import React, { useEffect } from 'react'
// import { browserhistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx"
import { Button, Text } from "../../atoms"
import Modal from '@material-ui/core/Modal';
import Card from '@material-ui/core/Card';

const useStyles = makeStyles(theme => ({
    root: {
    },
    card: {
        position: 'absolute',
        top: "calc(50% - 349px/2)",
        left: "calc(50% - 330px/2)",
        width: 330,
        height: 349,
    },
    image: {
        width: 330,
        height: 204,
        borderRadius: "9px 9px 0px 0px",
        border: "none",
    },
    text: {
        display: "block",
        width: 273,
        height: 45,
        fontSize: 12,
        margin: "24px auto 0 auto",
    },
    button: {
        position: 'absolute',
        right: "32px",
        bottom: "26px",
    },
}));

export default ({ children, LinkTo, imgSrc, className, onClose, open, setOpen, ...others }) => {
    const classes = useStyles();
    const [__open, __setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
        __setOpen(true);
    };

    const handleClose = function () {
        setOpen(false);
        __setOpen(false);
        onClose()
    };

    useEffect(() => {
        __setOpen(open)
        return () => { }
    }, [open])
    return (
        <div className={clsx(classes.root, className)} {...others}>
            <div onClick={handleOpen}>
                {children}
            </div>
            <Modal open={__open} onClose={handleClose}>
                <Card className={classes.card}>
                    <img className={classes.image} src={imgSrc} />
                    <Text color="default" className={classes.text}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed lorem ipsum dolor sit amet, consectetur adipiscing elit, sed</Text>
                    <Button className={classes.button} size="small" onClick={handleClose}>button</Button>
                </Card>
            </Modal>
        </div>
    )
}