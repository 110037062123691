import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Store } from "../../store"
import { Button } from '../atoms'
import { AppearFromBottom, ProductModalHeader } from '../molecules'
import { Product, Cart, Payment } from '../organisms';
import SwipeableViews from "react-swipeable-views";
import clsx from "clsx"
import order from "../../actions/Order/Order"


const useStyles = makeStyles({
    root: {
    },
    slide: {
        padding: 0,
        height: 300,
    },
    button: {
        "position": "absolute",
        "bottom": "16px",
        "left": "0",
    },
});

export default ({ className, ...others }) => {
    const classes = useStyles();
    const { globalState, setGlobalState } = useContext(Store)
    const setOpen = (open) => {
        setGlobalState({
            type: "SET_PRODUCTMODAL", payload: { open }
        })
    }
    const close = () => { console.log("on close"); setOpen(false) }
    const [index, setIndex] = useState(0)
    const [paymentUrl, setPaymentUrl] = useState("")
    const addAllProductsToCart = () => {
        Array.isArray(globalState.products) && globalState.products.map(product => {
            console.log({ variantId: product.variantId, id: product.id, amount: product.amount })
            setGlobalState({ type: "ADD_TO_CART", payload: { variantId: product.variantId, id: product.id, amount: product.amount } })
            product.amount = 1
            return product
        })
    }
    return (
        <>
            <AppearFromBottom closeButton open={globalState.openProductModal} onClose={close}>
                <ProductModalHeader tab={index} setTab={setIndex} />
                <SwipeableViews enableMouseEvents index={index} onChangeIndex={setIndex}>
                    <div className={classes.slide}>
                        <Product data={globalState.video.products} />
                    </div>
                    <div className={classes.slide}>
                        <Cart />
                    </div>
                    <div className={classes.slide}>
                        新しいページが開きます。
                    </div>
                </SwipeableViews>
                <Button
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={async () => {
                        const userId = "123456"
                        // [WIP]　ユーザーのglobalstateを作る。
                        switch (index) {
                            case 0:
                                addAllProductsToCart()
                                setIndex(1)
                                break
                            case 1:
                                const { invoice_url: paymenturl } = await order(globalState.cart, globalState.userId)
                                setGlobalState({ type: "EMPTY_CART", payload: {} })
                                setPaymentUrl(paymenturl)
                                console.log(paymenturl)
                                window.open(paymenturl);
                                setIndex(2)
                                break
                            case 2:
                                setIndex(0)
                                setOpen(false)
                                break
                        }
                    }
                    }>
                    {
                        index == 0 && "カートへ追加する" ||
                        index == 1 && "会計をする" ||
                        index == 2 && "オーダー完了" ||
                        "some error happens. reload page"
                    }
                </Button>
            </AppearFromBottom>
        </>
    )
}