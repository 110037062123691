import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { CounterBlack } from '../atoms'
import clsx from "clsx"
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { VerticalCenter } from '.';

const useStyles = makeStyles({
    root: {
        overflow: "hidden",
        height: "100%",
    },
    counter: {
        float: "right",
        width: '112px',
        height: "100%",
        marginRight: "20px",
    },
    xButton: {
        float: "right",
        width: '24px',
        height: "100%",
        marginRight: "20px",
    },
    checkButton: {
        float: "right",
        width: '24px',
        height: "100%",
        marginRight: "0px",
    },
});

export default ({ amount, setAmount, onXButton, onCheckButton, className, ...others }) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, className)} {...others}>
            <VerticalCenter className={classes.checkButton}>
                <CheckCircleIcon onClick={onCheckButton} />
            </VerticalCenter>
            <VerticalCenter className={classes.xButton}>
                <HighlightOffIcon onClick={onXButton} />
            </VerticalCenter>
            <VerticalCenter className={classes.counter}>
                <CounterBlack value={amount} setValue={setAmount} />
            </VerticalCenter>
        </div>
    )
}