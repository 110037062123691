import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx"
import { VideoListLargeItem, VideoListLargeContainer } from '../molecules'
import { useContext, useEffect } from 'react'
import { Store } from "../../store"

const useStyles = makeStyles({
    root: {
    },
});

export default ({ className, ...others }) => {
    const classes = useStyles();
    const { globalState, setGlobalState } = useContext(Store)
    console.log(globalState)
    if (globalState.searchResult.def.videos.length === undefined || globalState.searchResult.def.videos.length == 0)
        return (<><span>検索フォームに入力すると、検索結果を表示します</span></>)

    return (
        <>
            <VideoListLargeContainer className={clsx(classes.root, className)} {...others}>
                {globalState.searchResult.def.videos.map(({ title, videoUploader, views, imgSrc, relatedProduct }) => {
                    return (
                        <VideoListLargeItem title={title} videoUploader={videoUploader} relatedProduct={relatedProduct} views={views} imgSrc={imgSrc} size="large" />
                    )
                })}
            </VideoListLargeContainer>
        </>
    )
}