import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { FileUpload } from "../molecules"
import { AddVideoDescription, AddVideoProduct } from "."
import { Button } from '../atoms'
import SignInModal from "./SignInModal"
import addVideo from "../../actions/Video/addVideo"
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Store } from "../../store"
import clsx from 'clsx'

const useStyles = makeStyles({
    root: {
        width: "100%",
    },
    header: {
        "width": "calc(100% - 48px)",
        "height": "calc(52px - 30px)",
        "borderBottom": "0.5px solid #FFFFFF",
        "marginBottom": "8px",
        "padding": "15px 24px",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "bold",
        "fontSize": "16px",
        "lineHeight": "23px",
        "color": "#FFFFFF",
    },
    iconText: {
        height: "60px",
        width: "100%",
        borderBottom: "0.5px solid #FFFFFF"
    },
    icon: {
        "float": "left",
        "height": "100%",
        "width": "88px",
        "&>svg": {
            margin: "23px 0 0 30px"
        }
    },
    text: {
        "float": "left",
        "width": "calc(100% - 88px)",
        "height": "17px",
        padding: "21px 0 0 0",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "normal",
        "fontSize": "12px",
        "lineHeight": "17px",
        "color": "#FFFFFF"
    },
    button: {
        position: "fixed",
        bottom: "56px",
    },
});

export default ({ className, ...others }) => {
    const classes = useStyles();
    const { globalState, setGlobalState } = useContext(Store)
    const [addVideoDescription, setAddVideoDescription] = useState(false)
    const [addVideoProduct, setAddVideoProduct] = useState(false)
    const [description, setDescription] = useState("")
    const [productAdded, setProductAdded] = useState([])
    const [file, setFile] = useState()
    const [title, setTitle] = useState("testTitle")

    const [snack, setSnack] = useState()
    const [snackType, setSnackType] = useState("success")
    const [snackMessage, setSnackMessage] = useState("")
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnack(false);
    };

    const loading = () => { setGlobalState({ type: "SET_LOADING", payload: { loading: true } }) }
    const loadDone = () => { setGlobalState({ type: "SET_LOADING", payload: { loading: false } }) }
    const __setLoadPercent = (percent) => {
        setGlobalState({ type: "SET_PROGRESS", payload: { progress: percent } })
    }

    const IconText = ({ icon, text, onClick }) => {
        return (
            <div onClick={onClick} className={classes.iconText}>
                <div className={classes.icon}>
                    {icon}
                </div>
                <div className={classes.text}>
                    {text}
                </div>
            </div>
        )
    }
    return (
        <div className={clsx(classes.root, className)} {...others}>
            <div className={classes.header}>
                Record
            </div>
            <IconText
                icon={
                    <svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.75 10C0.948912 10 1.13968 10.079 1.28033 10.2197C1.42098 10.3603 1.5 10.5511 1.5 10.75V16C1.5 16.3978 1.65804 16.7794 1.93934 17.0607C2.22064 17.342 2.60218 17.5 3 17.5H21C21.3978 17.5 21.7794 17.342 22.0607 17.0607C22.342 16.7794 22.5 16.3978 22.5 16V10.75C22.5 10.5511 22.579 10.3603 22.7197 10.2197C22.8603 10.079 23.0511 10 23.25 10C23.4489 10 23.6397 10.079 23.7803 10.2197C23.921 10.3603 24 10.5511 24 10.75V16C24 16.7957 23.6839 17.5587 23.1213 18.1213C22.5587 18.6839 21.7956 19 21 19H3C2.20435 19 1.44129 18.6839 0.87868 18.1213C0.316071 17.5587 0 16.7957 0 16V10.75C0 10.5511 0.0790176 10.3603 0.21967 10.2197C0.360322 10.079 0.551088 10 0.75 10ZM7.5 5.281C7.64065 5.42161 7.83138 5.50059 8.03025 5.50059C8.22912 5.50059 8.41985 5.42161 8.5605 5.281L12 1.84L15.4395 5.2795C15.5087 5.35114 15.5914 5.40827 15.6829 5.44758C15.7744 5.48689 15.8729 5.50758 15.9724 5.50844C16.072 5.50931 16.1708 5.49033 16.263 5.45262C16.3551 5.41491 16.4389 5.35922 16.5093 5.2888C16.5797 5.21838 16.6354 5.13464 16.6731 5.04247C16.7108 4.9503 16.7298 4.85154 16.7289 4.75195C16.7281 4.65237 16.7074 4.55395 16.6681 4.46245C16.6288 4.37095 16.5716 4.28819 16.5 4.219L12.531 0.250004C12.4613 0.180159 12.3786 0.124745 12.2874 0.0869349C12.1963 0.0491252 12.0987 0.0296631 12 0.0296631C11.9013 0.0296631 11.8037 0.0491252 11.7125 0.0869349C11.6214 0.124745 11.5387 0.180159 11.469 0.250004L7.5 4.219C7.43016 4.28867 7.37474 4.37144 7.33693 4.46255C7.29912 4.55367 7.27966 4.65135 7.27966 4.75C7.27966 4.84865 7.29912 4.94634 7.33693 5.03745C7.37474 5.12857 7.43016 5.21134 7.5 5.281Z" fill="white" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 1C12.1989 1 12.3897 1.07902 12.5303 1.21967C12.671 1.36032 12.75 1.55109 12.75 1.75V13.75C12.75 13.9489 12.671 14.1397 12.5303 14.2803C12.3897 14.421 12.1989 14.5 12 14.5C11.8011 14.5 11.6103 14.421 11.4697 14.2803C11.329 14.1397 11.25 13.9489 11.25 13.75V1.75C11.25 1.55109 11.329 1.36032 11.4697 1.21967C11.6103 1.07902 11.8011 1 12 1Z" fill="white" />
                    </svg>
                }
                text={<FileUpload file={file} setFile={setFile}>upload video</FileUpload>}
            />
            <IconText
                icon={
                    <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M21.75 1.5H2.25C2.05109 1.5 1.86032 1.57902 1.71967 1.71967C1.57902 1.86032 1.5 2.05109 1.5 2.25V15.75C1.5 15.9489 1.57902 16.1397 1.71967 16.2803C1.86032 16.421 2.05109 16.5 2.25 16.5H21.75C21.9489 16.5 22.1397 16.421 22.2803 16.2803C22.421 16.1397 22.5 15.9489 22.5 15.75V2.25C22.5 2.05109 22.421 1.86032 22.2803 1.71967C22.1397 1.57902 21.9489 1.5 21.75 1.5ZM2.25 0C1.65326 0 1.08097 0.237053 0.65901 0.65901C0.237053 1.08097 0 1.65326 0 2.25L0 15.75C0 16.3467 0.237053 16.919 0.65901 17.341C1.08097 17.7629 1.65326 18 2.25 18H21.75C22.3467 18 22.919 17.7629 23.341 17.341C23.7629 16.919 24 16.3467 24 15.75V2.25C24 1.65326 23.7629 1.08097 23.341 0.65901C22.919 0.237053 22.3467 0 21.75 0H2.25Z" fill="white" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.5 5.25C4.5 5.05109 4.57902 4.86032 4.71967 4.71967C4.86032 4.57902 5.05109 4.5 5.25 4.5H18.75C18.9489 4.5 19.1397 4.57902 19.2803 4.71967C19.421 4.86032 19.5 5.05109 19.5 5.25C19.5 5.44891 19.421 5.63968 19.2803 5.78033C19.1397 5.92098 18.9489 6 18.75 6H5.25C5.05109 6 4.86032 5.92098 4.71967 5.78033C4.57902 5.63968 4.5 5.44891 4.5 5.25ZM4.5 9C4.5 8.80109 4.57902 8.61032 4.71967 8.46967C4.86032 8.32902 5.05109 8.25 5.25 8.25H18.75C18.9489 8.25 19.1397 8.32902 19.2803 8.46967C19.421 8.61032 19.5 8.80109 19.5 9C19.5 9.19891 19.421 9.38968 19.2803 9.53033C19.1397 9.67098 18.9489 9.75 18.75 9.75H5.25C5.05109 9.75 4.86032 9.67098 4.71967 9.53033C4.57902 9.38968 4.5 9.19891 4.5 9ZM4.5 12.75C4.5 12.5511 4.57902 12.3603 4.71967 12.2197C4.86032 12.079 5.05109 12 5.25 12H14.25C14.4489 12 14.6397 12.079 14.7803 12.2197C14.921 12.3603 15 12.5511 15 12.75C15 12.9489 14.921 13.1397 14.7803 13.2803C14.6397 13.421 14.4489 13.5 14.25 13.5H5.25C5.05109 13.5 4.86032 13.421 4.71967 13.2803C4.57902 13.1397 4.5 12.9489 4.5 12.75Z" fill="white" />
                    </svg>
                }
                text={"Video Description"}
                onClick={() => setAddVideoDescription(true)}
            />
            <IconText
                icon={
                    <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11 1.5C10.2044 1.5 9.44129 1.81607 8.87868 2.37868C8.31607 2.94129 8 3.70435 8 4.5V11.25C8 11.4489 7.92098 11.6397 7.78033 11.7803C7.63968 11.921 7.44891 12 7.25 12C7.05109 12 6.86032 11.921 6.71967 11.7803C6.57902 11.6397 6.5 11.4489 6.5 11.25V4.5C6.5 3.30653 6.97411 2.16193 7.81802 1.31802C8.66193 0.474106 9.80653 0 11 0C12.1935 0 13.3381 0.474106 14.182 1.31802C15.0259 2.16193 15.5 3.30653 15.5 4.5V11.25C15.5 11.4489 15.421 11.6397 15.2803 11.7803C15.1397 11.921 14.9489 12 14.75 12C14.5511 12 14.3603 11.921 14.2197 11.7803C14.079 11.6397 14 11.4489 14 11.25V4.5C14 3.70435 13.6839 2.94129 13.1213 2.37868C12.5587 1.81607 11.7956 1.5 11 1.5Z" fill="white" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.10759 9C3.92153 8.99981 3.74205 9.06879 3.604 9.19353C3.46595 9.31827 3.3792 9.48987 3.36059 9.675L1.99259 20.025C1.96114 20.3379 1.99565 20.654 2.09388 20.9528C2.19212 21.2516 2.35191 21.5264 2.56293 21.7596C2.77395 21.9928 3.03153 22.1792 3.31903 22.3068C3.60653 22.4343 3.91758 22.5001 4.23209 22.5H17.7681C18.0826 22.5001 18.3937 22.4343 18.6812 22.3068C18.9687 22.1792 19.2262 21.9928 19.4373 21.7596C19.6483 21.5264 19.8081 21.2516 19.9063 20.9528C20.0045 20.654 20.039 20.3379 20.0076 20.025L18.6381 9.675C18.6195 9.49013 18.533 9.31874 18.3952 9.19403C18.2575 9.06933 18.0784 9.00018 17.8926 9H4.10759ZM1.86809 9.525C1.92388 8.96988 2.18393 8.45529 2.59776 8.08109C3.01158 7.7069 3.54968 7.49981 4.10759 7.5H17.8926C18.4505 7.49981 18.9886 7.7069 19.4024 8.08109C19.8163 8.45529 20.0763 8.96988 20.1321 9.525L21.5001 19.878C21.5521 20.3993 21.4942 20.9257 21.3303 21.4233C21.1664 21.9208 20.9001 22.3785 20.5485 22.7669C20.1969 23.1552 19.7678 23.4656 19.2889 23.678C18.81 23.8904 18.292 24.0001 17.7681 24H4.23209C3.70808 24.0001 3.18987 23.8904 2.71087 23.6779C2.23187 23.4654 1.80272 23.1549 1.45109 22.7664C1.09947 22.3779 0.833165 21.92 0.669365 21.4222C0.505565 20.9245 0.447901 20.3979 0.500092 19.8765L1.86809 9.5265V9.525Z" fill="white" />
                    </svg>
                }
                text={"Attach product"}
                onClick={() => setAddVideoProduct(true)}
            />
            <AddVideoDescription open={addVideoDescription} setOpen={setAddVideoDescription} description={description} setDescription={setDescription} />
            <AddVideoProduct open={addVideoProduct} setOpen={setAddVideoProduct} productAdded={productAdded} setProductAdded={setProductAdded} />
            <Button
                className={classes.button}
                variant="contained"
                color="primary"
                fullWidth
                onClick={async () => {
                    try {
                        loading()
                        const result = await addVideo(__setLoadPercent, 0, file, title, description)
                        loadDone()
                        setSnack(true)
                        setSnackType("success")
                        setSnackMessage("動画を投稿しました")
                    } catch (e) {
                        loadDone()
                        setSnack(true)
                        setSnackType("error")
                        setSnackMessage("動画投稿に失敗しました")
                    }
                }}
            >
                動画を投稿する
            </Button>

            <Snackbar open={snack} autoHideDuration={6000} onClose={handleClose}>
                <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity={snackType}>
                    {snackMessage}
                </MuiAlert>
            </Snackbar>
            <SignInModal />
        </div >
    )
}