import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { SwitchAccount, AddVideo } from "../comoponents/organisms"
import Layout from '../comoponents/templates/Layout'

const useStyles = makeStyles({
    root: {
    },
});

export default ({ className, ...others }) => {
    const classes = useStyles();
    return (
        // <div className={clsx(classes.root, className)} {...others}>

        // </div>

        <Layout>
            <AddVideo />
        </Layout>
    )
}