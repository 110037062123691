import React from 'react'
import FormSignIn from "../comoponents/organisms/FormSignIn"
import Layout from '../comoponents/templates/Layout'

export default () => {
    return (
        <Layout>
            <FormSignIn />
        </Layout>
    )
}