import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { ProductNoIcon } from "."
import clsx from "clsx"

const useStyles = makeStyles({
    root: {
        width: "100%",
        overflow: "hidden"
    },
    main: {
        float: "left",
        width: "calc(100% - 50px)",
        transition: "all .3s;",
        height: "100%",
        "&.swiped": {
            width: "0",
        }
    },
    button: {
        float: "left",
        width: "50px",
        height: "100%",
    },
    extension: {
        float: "left",
        width: "0",
        transition: "all .3s;",
        height: "100%",
        "&.swiped": {
            width: "calc(100% - 50px)",
        }
    },
});

export default ({ setSwiped, mainContent, buttonContent, extensionContent, swiped, className, ...others }) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, className)} {...others}>
            {/* クリックしたら、横にいく */}
            <div className={clsx(classes.main, { "swiped": swiped })}>{mainContent}</div>

            {/* クリックしたら、横にいくボタン */}
            <div className={clsx(classes.button)} onClick={setSwiped}>{buttonContent}</div>

            {/* クリック後に展開されるビュー */}
            <div className={clsx(classes.extension, { "swiped": swiped })}>{extensionContent}</div>
        </div>
    )
}