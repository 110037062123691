import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx"

const useStyles = makeStyles({
    root: {
        width: "135px",
        height: "35px",
        position: "relative",
        background: "#000000",
        borderRadius: "4px",
    },
    left: {
        display: "table",
        float: "left",
        width: "28.14%",
        height: "100%",
        boxSizing: "border-box",
        borderRadius: "4px 0 0 4px",
    },
    center: {
        float: "left",
        width: "43.70%",
        height: "100%",
        boxSizing: "border-box",
        borderRadius: "0",
    },
    right: {
        display: "table",
        float: "left",
        width: "28.14%",
        height: "100%",
        boxSizing: "border-box",
        borderRadius: "0 4px 4px 0",
    },
    minus: {
        "display": "table-cell",
        "verticalAlign": "middle",
        "textAlign": "center",
        "width": "100%",
        "height": "17px",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "normal",
        "fontSize": "12px",
        "lineHeight": "17px",
        "color": "#FFFFFF"
    },
    count: {
        height: "100%",
        width: "100%",
        padding: "0",
        border: "solid 0px",
        textAlign: "center",
        "color": "#FFFFFF",
        background: "#000000",
        "-moz-appearance": "textfield", // firefox と IEでスピンボタンを消す
        "&::-webkit-outer-spin-button , &::-webkit-inner-spin-button": { // chromeでスピンボタンを消す。
            "-webkit-appearance": "none",
            margin: 0
        },
    },
    plus: {
        "display": "table-cell",
        "verticalAlign": "middle",
        "textAlign": "center",
        "width": "100%",
        "height": "17px",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "normal",
        "fontSize": "12px",
        "lineHeight": "17px",
        "color": "#FFFFFF"
    },
});

export default ({ className, value, setValue, ...others }) => {
    const classes = useStyles();
    const [count, setCount] = useState(value || 0)
    const changeValue = v => { setCount(v); if (!!setValue) setValue(v) }
    const handleChange = e => { changeValue(e.target.value) }
    const handleCountDown = e => { if (count > 0) changeValue(count - 1) }
    const handleCountUp = e => { changeValue((count || 0) + 1) }
    useEffect(() => {
        setCount(value)
        return () => { }
    }, [value])
    return (
        <div className={clsx(classes.root, className)} {...others}>
            <div className={classes.left} onClick={handleCountDown}>
                <div className={classes.minus} onClick={handleCountDown}>-</div>
            </div>
            <div className={classes.center}>
                <input type="number" className={classes.count} value={count} onChange={handleChange} />
            </div>
            <div className={classes.right} onClick={handleCountUp}>
                <div className={classes.plus} onClick={handleCountUp}>+</div>
            </div>
        </div>
    )
}