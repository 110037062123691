import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField';
import clsx from "clsx"
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles({
    label: {
        color: "white"
    },
    focusedLabel: {
        color: "white"
    },
    input: {
        "& > div > input": {
            test: "test",
            "&:-webkit-autofill": {
                "-webkit-box-shadow": "0 0 0 1000px rgba(0,0,0,0.5) inset",
                "-webkit-text-fill-color": "white !important",
            },
        }
    }
})

export default (props) => {
    const classes = useStyles();
    const [error, setError] = useState(false)

    const validate = (pattern, value) => {
        const found = value.match(pattern);
        setError(!found)
    }

    const handleChange = (event) => {
        if (!!props.pattern)
            validate(props.pattern, event.target.value)

        else if (!!props.type && props.type == "mail")
            validate(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, event.target.value)

        if (!!props.onChange)
            props.onChange(event.target.value)
    }
    return (
        <TextField {...props} error={!!error} onChange={handleChange}
            InputLabelProps={{
                classes: {
                    root: classes.label,
                    focused: classes.focusedLabel,
                    error: classes.erroredLabel
                },
            }}
            className={clsx(classes.input, props.className)}
        ></TextField>
    )
}

