import React, { useContext, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { FullSizeModal, VideoListSmallItem } from '../molecules'
import clsx from "clsx"
import { Store } from "../../store"
import { useEffect } from 'react';
import { theme } from '../templates/ColorProvider'
import { useHistory, } from "react-router-dom"

const useStyles = makeStyles({
    root: {
        width: "100%",
        background: theme.palette.background.default,
    },
    header: {
        "width": "100%",
        "height": "52px",
        "borderBottom": "0.5px solid #FFFFFF",
        "marginBottom": "20px",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "bold",
        "fontSize": "16px",
        "lineHeight": "23px",
        "color": "#FFFFFF",
    },
    headerIcon: {
        float: "left",
        margin: "19px 0 0 18px",
    },
    headerText: {
        "float": "left",
        "width": "calc(100% - 100px)",
        "height": "22px",
        "padding": 0,
        "margin": "15px 0 0 32px",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "bold",
        "fontSize": "16px",
        "lineHeight": "23px",
        "color": "#FFFFFF",
    },
    listItem: {
        margin: "10px 20px 10px 20px",
        width: "calc(100% - 40px)"
    },
});

export default ({ open, onClose, title, list, LinkTo, className, ...others }) => {
    const classes = useStyles();
    const history = useHistory()

    const ArrowRight = ({ className, ...others }) => {
        return (
            <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.0002 11V13H8.00016L13.5002 18.5L12.0802 19.92L4.16016 12L12.0802 4.08002L13.5002 5.50002L8.00016 11H20.0002Z" fill="white" />
            </svg>
        )
    }
    return (
        <>
            <FullSizeModal open={open} onClose={onClose} className={clsx(classes.root, className)} {...others} >
                <div className={classes.header} onClick={onClose}>
                    <ArrowRight className={classes.headerIcon} />
                    <span className={classes.headerText}>{title}</span>
                </div>
                {Array.isArray(list) && list.map(({ id, title, videoUploader, views, imgSrc }) => {
                    return (
                        <VideoListSmallItem
                            onClick={() => { history.push(`/Video/${id}`) }}
                            className={classes.listItem}
                            title={title}
                            videoUploader={videoUploader}
                            views={views}
                            imgSrc={imgSrc}
                        />
                    )
                })}
            </FullSizeModal>
        </>
    )
}