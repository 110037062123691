import React, { useState, useEffect, useRef, useContext } from 'react'
import { useHistory, } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles';
import { VerticalCenter } from "../molecules"
import { ModalAddVideoToVideoList } from '../organisms';
import clsx from "clsx"
import { Store } from "../../store"
import addVideoToVideoList from "../../actions/Video/addVideoToVideoList"
import getVideo from "../../actions/Video/getVideo"
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';

const useStyles = makeStyles({
    root: {
        position: "relative",
        height: "calc(100vh - 56px)"
    },
    iconWrapper: {
        width: "58px"
    },
    video: {
        position: "relative",
        width: "100%",
        height: "100%",
        objectFit: "cover"
    },
    overlay: {
        paddingTop: "100px",
        width: "100%",
        position: "absolute",
        "display": "block",
        zIndex: '10',
        bottom: "0",
        "background": "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 96.14%), url(.jpg)"
    },
    actionContainer: {
        width: "100%",
        height: "48px",
    },
    icons: {
        width: "25px",
        height: "25px",
        marginLeft: "25px",
        marginBottom: "35px",
    },
    description: {
        margin: "0 21px 0 21px",
        "height": "68px",
        "width": "calc(100% - 50px)",
        "height": "68px",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "bold",
        "fontSize": "12px",
        "lineHeight": "17px",
        "color": "#FFFFFF"
    },
    togglePlayVideoButton: {
        // paddingBottom: "100px",
        width: "100%",
        height: "100%",
        padding: "0",
        position: "absolute",
        zIndex: '5',
        bottom: "0",
    },
    playIcon: {
        "opacity": "1",
        "&.hide": {
            "opacity": "0",
            display: "none",
        },
    },
    pauseIcon: {
        "opacity": "1",
        display: "none",
        "&.hide": {
            "opacity": "0",
            display: "none",
        },
    },
    toggleMuteButton: {
        top: "10px",
        left: "10px",
        // width: "100%",
        // height: "calc(100% - 100px)",
        position: "absolute",
        zIndex: '6',
        // bottom: "0",
    },
});

export default ({ id, className, ...others }) => {
    const history = useHistory();
    const classes = useStyles();
    const { globalState, setGlobalState } = useContext(Store)
    const [videoListModal, setVideoListModal] = useState(false)
    const [videoEnded, setVideoEnded] = useState(false)
    const [isPlaying, setIsPlaying] = useState(false)
    const [isMuted, setIsMuted] = useState(true)
    const [isHidePlayBUtton, setIsHidePlayButton] = useState(false)

    const videoRef = useRef()

    useEffect(() => {
        // videoが終わった時に呼ばれる
        if (videoEnded) playNextVideo()
        setVideoEnded(false)
        return () => { }
    }, [videoEnded])

    const playNextVideo = () => {
        history.push(`/Video/${globalState.video.nextVideoId}`)
    }

    const isVideoElement = el => {
        const objectType = Object.prototype.toString.call(el)
        const matchType = "[object HTMLVideoElement]"
        return objectType === matchType
    }
    const checkPaused = () => {
        if (!("current" in videoRef) || !isVideoElement(videoRef.current) || !("paused" in videoRef.current))
            return;
        setIsPlaying(!videoRef.current.paused)
    }
    useEffect(() => {
        checkPaused()
        return () => { }
    }, [])

    const togglePlayVideo = () => {
        if (!videoRef.current.paused)
            videoRef.current.pause()
        else
            videoRef.current.play()
        return
    }
    const getVideoId = (id) => {
        if (id == undefined)
            return undefined
        if (id == "undefined")
            return undefined
        return id || undefined

    }
    const __getVideo = async (id) => {
        const data = await getVideo(getVideoId(id))
        if ("error" in data) {
            console.error(data.payload)
            return
        }
        setGlobalState({
            type: "SET_VIDEO", payload: {
                video: {
                    videoId: data.videoId,
                    videoSrc: data.videoSrc,
                    like: data.like,
                    isLiked: data.isLiked,
                    userName: data.userName,
                    description: data.description,
                    products: data.products,
                    nextVideoId: data.nextVideoId,
                }
            }
        })
    }

    const openProductModal = () => {
        setGlobalState({
            type: "SET_PRODUCTMODAL", payload: { open: true }
        })
    }
    useEffect(() => {
        __getVideo(id)
        return () => {
        }
    }, [id])

    const toggleMuted = () => {
        videoRef.current.muted = !isMuted
    }


    useEffect(() => {
        bindPlayVideo()
        return () => { }
    }, [globalState.video])
    const bindPlayVideo = () => {
        videoRef.current.onvolumechange = async (event) => {
            setIsMuted(event.srcElement.muted)
        }
        videoRef.current.onpause = async (event) => {
            setIsPlaying(false)
        }
        videoRef.current.onplay = async (event) => {
            setIsPlaying(true)
        }
        videoRef.current.onerror = async (event) => {
            console.error("error", event)
            __getVideo(globalState.video.nextVideoId)
        }
        videoRef.current.onloadedmetadata = async (event) => {
            event.target.currentTime = 0
            await event.target.play()
        }
        videoRef.current.onended = (event) => {
            // videoが終わった時に呼ばれる
            // 直接playNextVideoを呼ぶと、スコープが違うからうまくいかなかった。
            setVideoEnded(true)
        };
    }

    const setIsLiked = (isLiked) => {
        setGlobalState({
            type: "SET_VIDEO", payload: {
                video: {
                    ...globalState.video.video,
                    isLiked,
                }
            }
        })
    }
    const PlayVideoButton = () => {
        return (
            <>
                <VerticalCenter className={classes.togglePlayVideoButton} onClick={() => { togglePlayVideo() }}>
                    <PauseCircleFilledIcon className={clsx(classes.pauseIcon, { "hide": !isPlaying })} />
                    <PlayArrowIcon className={clsx(classes.playIcon, { "hide": isPlaying })} />
                </VerticalCenter>
            </>
        )
    }
    const MuteButton = () => {
        return <>
            {isMuted ?
                <VolumeOffIcon className={classes.toggleMuteButton} onClick={toggleMuted}>muted</VolumeOffIcon> :
                <VolumeUpIcon className={classes.toggleMuteButton} onClick={toggleMuted}>unmuted</VolumeUpIcon>
            }
        </>
    }
    return (
        <div className={clsx(classes.root, className)} {...others}>
            <video
                className={classes.video}
                src={globalState.video.videoSrc}
                ref={videoRef}
                autoPlay
                playsInline
                muted="muted"
            />
            <PlayVideoButton />
            <MuteButton />
            <div className={classes.overlay}>
                <div className={classes.description}>
                    {globalState.video.description}
                </div>
                <div className={classes.actionContainer}>
                    <svg className={classes.icons} onClick={openProductModal} width="26" height="28" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13 1.75C12.0717 1.75 11.1815 2.11875 10.5251 2.77513C9.86875 3.4315 9.5 4.32174 9.5 5.25V13.125C9.5 13.3571 9.40781 13.5796 9.24372 13.7437C9.07962 13.9078 8.85706 14 8.625 14C8.39294 14 8.17038 13.9078 8.00628 13.7437C7.84219 13.5796 7.75 13.3571 7.75 13.125V5.25C7.75 3.85761 8.30312 2.52226 9.28769 1.53769C10.2723 0.553123 11.6076 0 13 0C14.3924 0 15.7277 0.553123 16.7123 1.53769C17.6969 2.52226 18.25 3.85761 18.25 5.25V13.125C18.25 13.3571 18.1578 13.5796 17.9937 13.7437C17.8296 13.9078 17.6071 14 17.375 14C17.1429 14 16.9204 13.9078 16.7563 13.7437C16.5922 13.5796 16.5 13.3571 16.5 13.125V5.25C16.5 4.32174 16.1313 3.4315 15.4749 2.77513C14.8185 2.11875 13.9283 1.75 13 1.75Z" fill="white" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.95878 10.5C4.74171 10.4998 4.53231 10.5803 4.37125 10.7258C4.2102 10.8713 4.10898 11.0715 4.08728 11.2875L2.49128 23.3625C2.45458 23.7276 2.49484 24.0963 2.60945 24.4449C2.72406 24.7935 2.91048 25.1142 3.15667 25.3862C3.40286 25.6583 3.70337 25.8758 4.03879 26.0245C4.37421 26.1733 4.73709 26.2501 5.10403 26.25H20.896C21.263 26.2501 21.6258 26.1733 21.9613 26.0245C22.2967 25.8758 22.5972 25.6583 22.8434 25.3862C23.0896 25.1142 23.276 24.7935 23.3906 24.4449C23.5052 24.0963 23.5455 23.7276 23.5088 23.3625L21.911 11.2875C21.8893 11.0718 21.7884 10.8719 21.6277 10.7264C21.467 10.5809 21.258 10.5002 21.0413 10.5H4.95878ZM2.34603 11.1125C2.41112 10.4649 2.7145 9.8645 3.1973 9.42794C3.6801 8.99139 4.30787 8.74978 4.95878 8.75H21.0413C21.6922 8.74978 22.32 8.99139 22.8027 9.42794C23.2855 9.8645 23.5889 10.4649 23.654 11.1125L25.25 23.191C25.3107 23.7992 25.2432 24.4133 25.052 24.9938C24.8607 25.5743 24.55 26.1083 24.1398 26.5614C23.7296 27.0144 23.229 27.3765 22.6703 27.6243C22.1116 27.8721 21.5072 28.0001 20.896 28H5.10403C4.49268 28.0001 3.8881 27.8721 3.32927 27.6242C2.77044 27.3763 2.26976 27.014 1.85953 26.5608C1.44929 26.1075 1.13861 25.5733 0.947511 24.9926C0.756411 24.4119 0.689136 23.7976 0.750026 23.1893L2.34603 11.1142V11.1125Z" fill="white" />
                    </svg>
                    {!globalState.video.isLiked
                        ?
                        <svg
                            className={classes.icons}
                            onClick={() => { addVideoToVideoList(globalState.video.videoId, globalState.video.LikeVideoListId || 0); setIsLiked(true) }}
                            width="27" height="25" viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4552 4.9175L12.3145 3.74496C9.63692 0.992607 4.72722 1.94241 2.9549 5.40274C2.12282 7.03029 1.93509 9.38014 3.45446 12.3791C4.91814 15.2667 7.96323 18.7254 13.4552 22.4928C18.9472 18.7254 21.9907 15.2667 23.456 12.3791C24.9753 9.37854 24.7892 7.03029 23.9555 5.40274C22.1832 1.94241 17.2735 0.991017 14.5959 3.74337L13.4552 4.9175ZM13.4552 24.4099C-10.9389 8.29033 5.9443 -4.29096 13.1752 2.36401C13.2707 2.45152 13.3645 2.5422 13.4552 2.63607C13.545 2.54229 13.6384 2.45206 13.7352 2.3656C20.9645 -4.29414 37.8494 8.28874 13.4552 24.4099Z" fill="white" />
                        </svg>
                        :
                        <svg
                            className={clsx(classes.icons)}
                            onClick={() => { setIsLiked(false) }}
                            width="84" height="79" viewBox="0 0 84 79" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M42.0001 6.89877C65.2996 -17.0517 123.554 24.859 42.0001 78.7503C-39.5534 24.8643 18.7006 -17.0517 42.0001 6.89877Z" fill="red" />
                        </svg>
                    }
                    <svg className={classes.icons} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M24.5522 0.818509C24.6773 0.943695 24.7615 1.10387 24.7937 1.2779C24.8259 1.45192 24.8045 1.63162 24.7325 1.79326L14.8327 24.0673C14.7636 24.223 14.6504 24.3552 14.5072 24.4476C14.3639 24.5399 14.1968 24.5884 14.0264 24.5871C13.8559 24.5858 13.6896 24.5348 13.5477 24.4403C13.4059 24.3457 13.2948 24.2118 13.228 24.055L9.65625 15.7145L1.31399 12.141C1.15776 12.0738 1.0245 11.9625 0.930507 11.8208C0.836513 11.6791 0.785862 11.513 0.784748 11.3429C0.783634 11.1729 0.832106 11.0062 0.924235 10.8632C1.01636 10.7203 1.14816 10.6072 1.30349 10.538L23.5775 0.638259C23.7389 0.566592 23.9183 0.545456 24.0919 0.577627C24.2656 0.609799 24.4254 0.693773 24.5505 0.818509H24.5522ZM3.84624 11.322L10.666 14.2445C10.8722 14.3334 11.0364 14.4982 11.1245 14.7048L14.047 21.5245L22.209 3.16001L3.84624 11.322Z" fill="white" />
                    </svg>
                    <svg
                        className={classes.icons}
                        onClick={() => { setVideoListModal(true) }}
                        width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M14 21L22.75 26.25V5.25C22.75 4.32174 22.3813 3.4315 21.7249 2.77513C21.0685 2.11875 20.1783 1.75 19.25 1.75H8.75C7.82174 1.75 6.9315 2.11875 6.27513 2.77513C5.61875 3.4315 5.25 4.32174 5.25 5.25V26.25L14 21ZM7 23.1595L14 18.9595L21 23.1595V5.25C21 4.78587 20.8156 4.34075 20.4874 4.01256C20.1592 3.68437 19.7141 3.5 19.25 3.5H8.75C8.28587 3.5 7.84075 3.68437 7.51256 4.01256C7.18437 4.34075 7 4.78587 7 5.25V23.1595Z" fill="white" />
                    </svg>
                </div>
            </div>
            <ModalAddVideoToVideoList videoId={globalState.video.videoId} open={videoListModal} setOpen={setVideoListModal} />
        </div >
    )
}