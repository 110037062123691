import axios from 'axios'
import URLJoin from '../URLJoin'
import AccessServer from '../AccessServer'
import random from "../Random"

export default async (userId) => {
    try {
        if (AccessServer()) {
            const uri = URLJoin(process.env.REACT_APP_URL_BACKEND, "/api/getVideoListList/", userId)
            const { data: videoList } = await axios.get(uri)
            return videoList
        } else {
            const videoList = {
                id: 1000,
                name: "VideoList",
            }
            const data = [
                { ...videoList, id: random() },
                { ...videoList, id: random() },
                { ...videoList, id: random() },
            ]
            return data
        }
    }
    catch (err) {
        throw new Error("Network Error")
    }
}