import React, { useContext, useState } from 'react'
import { browserhistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { FullSizeModal } from '../molecules'
import clsx from "clsx"
import { Store } from "../../store"
import { useEffect } from 'react';

const useStyles = makeStyles({
    root: {
        width: "100%",
    },
    addressWrapper: {
        "width": "calc(100% - 40px)",
        "margin": "0 20px",
        "borderBottom": "0.5px solid #cfcfcf",
        "boxSizing": "border-box",
        display: "inline-block",
    },
    address: {
        float: "left",
        "width": "calc(100% - 20px)",
        "height": "17px",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "normal",
        "fontSize": "12px",
        "lineHeight": "17px",
        "color": "#000000",
        "flex": "none",
        "order": "0",
        "alignSelf": "center",
        "flexGrow": "0",
        "margin": "16px 0px 16px 0px"
    },
    editButton: {
        "margin": "16px 0px 16px 0px",
        "float": "right",
        "width": "20px",
        "height": "14px",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "bold",
        "fontSize": "10px",
        "lineHeight": "14px",
        "color": "#000000",
        "flex": "none",
        "order": "1",
        "alignSelf": "center",
        "flexGrow": "0",
    },
});

export default ({ className, ...others }) => {
    const classes = useStyles();
    const { globalState, setGlobalState } = useContext(Store)
    const onClose = () => {
        setGlobalState({ type: "SET_OPEN_TIME_SELECT_MODAL", payload: { openTimeSelectModal: false } })
    }

    useEffect(() => {
        return () => { }
    }, [])
    const setTime = (time) => {
        setGlobalState({ type: "SET_SHIPPING_TIME", payload: { time } })
    }

    const Time = ({ time, key, ...others }) => {
        return (
            <div className={classes.addressWrapper} onClick={() => { setTime(key); onClose() }}>
                <div className={classes.address}>
                    {time}
                </div>
                <div className={classes.editButton}>
                    <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.145917 0.146039C0.192363 0.0994761 0.247538 0.0625333 0.308283 0.0373268C0.369028 0.0121204 0.43415 -0.000854492 0.499917 -0.000854492C0.565684 -0.000854492 0.630806 0.0121204 0.691551 0.0373268C0.752296 0.0625333 0.807471 0.0994761 0.853917 0.146039L6.85392 6.14604C6.90048 6.19248 6.93742 6.24766 6.96263 6.3084C6.98784 6.36915 7.00081 6.43427 7.00081 6.50004C7.00081 6.56581 6.98784 6.63093 6.96263 6.69167C6.93742 6.75242 6.90048 6.80759 6.85392 6.85404L0.853917 12.854C0.760031 12.9479 0.632693 13.0007 0.499917 13.0007C0.367141 13.0007 0.239804 12.9479 0.145917 12.854C0.0520303 12.7602 -0.000714779 12.6328 -0.000714779 12.5C-0.000714779 12.3673 0.0520303 12.2399 0.145917 12.146L5.79292 6.50004L0.145917 0.854039C0.0993537 0.807594 0.0624107 0.752418 0.0372043 0.691673C0.0119978 0.630928 -0.000976562 0.565807 -0.000976562 0.500039C-0.000976562 0.434272 0.0119978 0.369151 0.0372043 0.308406C0.0624107 0.247661 0.0993537 0.192485 0.145917 0.146039Z" fill="black" />
                    </svg>
                </div>
            </div>
        )
    }
    return (
        <>
            <FullSizeModal open={globalState.openTimeSelectModal} onClose={onClose} className={clsx(classes.root, className)} {...others} >
                <Time time="朝中" key="am" />
                <Time time="1-3時" key="1to3" />
                <Time time="3-5時" key="3to5" />
                <Time time="5-7時" key="5to7" />
                <Time time="7-9時" key="7to9" />
            </FullSizeModal>
        </>
    )
}