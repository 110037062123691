import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardArrowDownContainer, Account } from "../../molecules"

const useStyles = makeStyles({
    root: {
        width: "100%",
    },
    accountWrapper: {
        padding: "10px 25px 0 25px"
    }
});

export default () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <KeyboardArrowDownContainer popoverContent={<>
                <div className={classes.accountWrapper}>
                    <Account userName={"userName"}></Account>
                </div>
                <div className={classes.accountWrapper}>
                    <Account userName={"userName"}></Account>
                </div>
            </>}>
                <Account userName={"userName"}></Account>
            </KeyboardArrowDownContainer >
        </div >
    )
}