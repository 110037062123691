import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx"
import { ProductDescription } from "../comoponents/organisms"
import Layout from '../comoponents/templates/Layout'

const useStyles = makeStyles({
    root: {
    },
});

export default ({ match, className, ...others }) => {
    const classes = useStyles();
    const { params } = match
    const id = parseInt(params.id, 10)
    return (
        <Layout>
            <ProductDescription id={id} />
        </Layout>
    )
}