import React, { useState, useContext, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx"
import { Store } from "../../store"

const useStyles = makeStyles({
    root: {
        overflowY: "scroll",
        height: "300px",
        paddingBottom: "60px",
    },
    box: {
        height: "34px"
    },
    label: {
        "float": "left",
        "width": "145px",
        "height": "17px",
        "left": "36px",
        "t dop": "440px",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "bold",
        "fontSize": "12px",
        "lineHeight": "17px",
        "color": "#000000"
    },
    text: {
        "float": "right",
        "marginRight": "14.5px",
        "width": "145px",
        "height": "17px",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "normal",
        "fontSize": "12px",
        "lineHeight": "17px",
        "color": "#979797",
        "textAlign": "right",
    },
    icon: {
        "float": "right",
    },
    button: {
        "position": "absolute",
        "bottom": "16px",
        "left": "0",
    },
});

export default ({ className, ...others }) => {
    const classes = useStyles();
    const { globalState, setGlobalState } = useContext(Store)
    const setOpenSelectAddressModal = () => {
        setGlobalState({ type: "SET_OPEN_ADDRESS_SELECT_MODAL", payload: { openAddressSelectModal: true } })
    }
    const setOpenSelectTimeModal = () => {
        setGlobalState({ type: "SET_OPEN_TIME_SELECT_MODAL", payload: { openTimeSelectModal: true } })
    }
    const setOpenSelectPaymentModal = () => {
        setGlobalState({ type: "SET_OPEN_PAYMENT_SELECT_MODAL", payload: { openPaymentSelectModal: true } })
    }

    useEffect(() => {
        return () => { }
    }, [])
    const Icon = ({ className }) => {
        return (
            <div className={className}>
                <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.645917 0.646001C0.692363 0.599438 0.747538 0.562495 0.808283 0.537289C0.869028 0.512082 0.93415 0.499107 0.999917 0.499107C1.06568 0.499107 1.13081 0.512082 1.19155 0.537289C1.2523 0.562495 1.30747 0.599438 1.35392 0.646001L7.35392 6.646C7.40048 6.69245 7.43742 6.74762 7.46263 6.80837C7.48784 6.86911 7.50081 6.93423 7.50081 7C7.50081 7.06577 7.48784 7.13089 7.46263 7.19163C7.43742 7.25238 7.40048 7.30755 7.35392 7.354L1.35392 13.354C1.26003 13.4479 1.13269 13.5006 0.999917 13.5006C0.867141 13.5006 0.739804 13.4479 0.645917 13.354C0.55203 13.2601 0.499285 13.1328 0.499285 13C0.499285 12.8672 0.55203 12.7399 0.645917 12.646L6.29292 7L0.645917 1.354C0.599354 1.30756 0.562411 1.25238 0.537204 1.19163C0.511998 1.13089 0.499023 1.06577 0.499023 1C0.499023 0.934234 0.511998 0.869113 0.537204 0.808368C0.562411 0.747622 0.599354 0.692447 0.645917 0.646001Z" fill="#979797" />
                </svg>
            </div>)
    }
    const LabelWithText = ({ label, text, onClick, others }) => {
        return (
            <div className={classes.box} onClick={onClick}{...others}>
                <div className={classes.label}>{label}</div>
                <Icon className={classes.icon} />
                <div className={classes.text}>{text}</div>
            </div>
        )
    }
    return (
        <>
            <div className={clsx(classes.root, className)} {...others}>
                <LabelWithText label="Address" text="Select address" onClick={() => { setOpenSelectAddressModal(true) }} />
                <LabelWithText label="Time" text="Select Time" onClick={() => { setOpenSelectTimeModal(true) }} />
                <LabelWithText label="Payment" text="Select Payment Method" onClick={() => { setOpenSelectPaymentModal(true) }} />
            </div>
        </>
    )
}