import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx"

const useStyles = makeStyles({
    root: {
        width: "calc(100% - 64px)",
        height: "49px",
        margin: "0",
        padding: "0 32px",
        border: "solid",
        borderColor: "#CFCFCF",
        borderWidth: "1px 0px 1px 0px",
        "& + *": {
            borderWidth: "0px 0px 1px 0px",
        },
    },
});

export default ({ className, ...others }) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, className)} {...others} />
    )
}