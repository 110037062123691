import axios from 'axios'

export const createAccount = async (userName, email, password) => {
    try {
        const param = { userName, email, password }
        // const res = await axios.get(`${process.env.REACT_APP_URL_ADDRESS_ZIP}/${first}/${second}.json`);
        const data = { token: "LQwDde6x8eV4ROOCOdSW" }//ダミー
        return data;
    }
    catch (err) {
    }
}

export const getProfile = async (userId) => {
    try {
        const param = { userId }
        // const res = await axios.get(`${process.env.REACT_APP_URL_ADDRESS_ZIP}/${first}/${second}.json`);
        const data = { userName: "user name", introduction: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed or sit amet, consectetur adipiscing elit, sed" } //ダミー
        return data;
    }
    catch (err) {
    }
}
