import React, { useState, useContext, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { AppearFromBottom, ProductModalCounter, Swipe, ProductNoIcon, VerticalCenter, ProductModalHeader } from '../molecules'
import { ProductDetailModal } from '.'
import { Button } from '../atoms'
import clsx from "clsx"
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import { Store } from "../../store"
// import Button from "@material-ui/core/Button"

const useStyles = makeStyles({
    root: {
        overflowY: "scroll",
        height: "300px",
        paddingBottom: "60px",
    },
    swipe: {
        height: "96px"
    },
    productWrapper: {
    },
    button: {
        "position": "absolute",
        "bottom": "16px",
        "left": "0",
    },
});

export default ({ data, className, ...others }) => {
    const classes = useStyles();
    const { globalState, setGlobalState } = useContext(Store)
    const setProducts = (products) => {
        setGlobalState({ type: "SET_PRODUCTS", payload: { products } })
    }
    const addToCart = (id, amount, variantId) => {
        setGlobalState({ type: "ADD_TO_CART", payload: { id, amount, variantId } })
    }
    useEffect(() => {
        if (Array.isArray(data))
            setProducts(data.map(product => {
                product.amount = 1
                product.swiped = false
                product.open = false
                return product
            }))
        return () => { }
    }, [data])
    useEffect(() => {
        console.log("globalstate products", globalState.products)
        return () => {
        }
    }, [globalState.products])
    return (
        <div className={clsx(classes.root, className)} {...others}>
            {Array.isArray(globalState.products) && globalState.products.map(({ variantId, id, name, brandName, price, imgSrc, amount, swiped }, index) => {
                const setAmount = (amount) => {
                    setProducts(globalState.products.map(product => {
                        if (product.id == id)
                            product.amount = amount
                        return product
                    }))
                }
                const setSwiped = (swiped) => {
                    setProducts(globalState.products.map(product => {
                        if (product.id == id)
                            product.swiped = swiped
                        return product
                    }))
                }
                const setOpen = (open) => {
                    console.log({ open, amount, imgSrc, name, brandName, price })
                    setGlobalState({
                        type: "SET_PRODUCT_DETAIL",
                        payload: {
                            productDetailModal: { open, index }
                        }
                    })
                }
                const toggleSwipe = () => setSwiped(!swiped)
                return (
                    <><Swipe
                        className={classes.swipe}
                        swiped={swiped}
                        setSwiped={toggleSwipe}
                        mainContent={
                            <ProductNoIcon
                                name={name}
                                brandName={brandName}
                                price={price}
                                imgSrc={imgSrc}
                                onClick={() => { console.log("on click"); setOpen(true) }}
                            />
                        }
                        buttonContent={
                            <VerticalCenter>
                                <AddShoppingCartIcon />
                            </VerticalCenter>
                        }
                        extensionContent={
                            <ProductModalCounter
                                amount={amount}
                                setAmount={setAmount}
                                onXButton={toggleSwipe}
                                onCheckButton={() => {
                                    console.log(id, amount, variantId)
                                    addToCart(id, amount, variantId)
                                    setAmount(0)
                                    toggleSwipe()
                                }}
                            />
                        }
                    />
                    </>
                )
            })}
        </div>
    )
}