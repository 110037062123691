import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx"
import { Text, Counter } from "../../atoms"

const useStyles = makeStyles({
    root: {
        width: "100%",
        height: "61px",
    },
    text: {
        float: "left",
        marginLeft: "31px",
        marginTop: "20px",
    },
    counter: {
        float: "Right",
        marginRight: "40px",
        marginTop: "15px",
    },
});

export default ({ value, setValue, className, ...others }) => {
    const classes = useStyles();
    useEffect(() => {
        return () => {
        }
    }, [value])
    return (
        <div className={clsx(classes.root, className)} {...others}>
            <Text className={classes.text}>量</Text>
            <Counter className={classes.counter} value={value} setValue={setValue} />
        </div>
    )
}