import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx"

const useStyles = makeStyles({
    root: {
    },
    glay: {
        color: '#979797'
    },
    white: {
        color: '#ffffff'
    },
    default: {
        color: '#000000'
    },
});

export default ({ color, className, children, ...others }) => {
    const classes = useStyles();
    return (
        <span className={clsx(classes.root, className, { glay: color == "glay", white: color == "white", default: color == "default", })} {...others}>
            {children}
        </span >
    )
}