import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx"
import { VideoListMidiumItem, VideoListMidiumContainer, List } from '../../molecules'
import { VideoListModal } from '../'
import { useState, useEffect } from 'react'
import { getVideoProfile } from "../../../actions/__Video"
import { useHistory, } from "react-router-dom"

const useStyles = makeStyles({
    root: {
    },
});

export default ({ userId, key, className, ...others }) => {
    const classes = useStyles();
    const history = useHistory()
    const [data, setData] = useState([])
    const [name, setName] = useState([])
    const [open, setOpen] = useState(false)
    const getData = async () => {
        const __data = await getVideoProfile(userId, key)
        setData(__data.videos)
        setName(__data.name)
    }
    useEffect(() => {
        getData()
        return () => { }
    }, [])
    return (
        <>
            <List text={name}
                onClick={() => { setOpen(true) }}
            >
                <VideoListMidiumContainer className={clsx(classes.root, className)} {...others}>
                    {data.map(({ id, title, videoUploader, views, imgSrc }) => {
                        return (
                            <VideoListMidiumItem
                                title={title}
                                videoUploader={videoUploader}
                                views={views}
                                imgSrc={imgSrc}
                                onClick={() => { history.push(`/Video/${id}`) }}
                            />
                        )
                    })}
                </VideoListMidiumContainer>
            </List>
            <VideoListModal
                open={open}
                onClose={() => { setOpen(false) }}
                list={data}
                title={name}
            />
        </>
    )
}