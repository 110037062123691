import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { CategoryList4x2Container, CategoryList4x2Item } from "../../molecules"
import GridOnIcon from '@material-ui/icons/GridOn';

import clsx from "clsx"

const useStyles = makeStyles({
    root: {
    },
});

export default ({ className, ...others }) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, className)} {...others}>
            <CategoryList4x2Container>
                <CategoryList4x2Item icon={<GridOnIcon />} text="Food" />
                <CategoryList4x2Item icon={<GridOnIcon />} text="Fashion" />
                <CategoryList4x2Item icon={<GridOnIcon />} text="お土産" />
                <CategoryList4x2Item icon={<GridOnIcon />} text="Category" />
                <CategoryList4x2Item icon={<GridOnIcon />} text="Category" />
                <CategoryList4x2Item icon={<GridOnIcon />} text="Category" />
                <CategoryList4x2Item icon={<GridOnIcon />} text="Category" />
                <CategoryList4x2Item icon={<GridOnIcon />} text="Category" />
            </CategoryList4x2Container>
        </div>
    )
}