import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Video, ProductModals, ProductDetailModal } from '../comoponents/organisms'
import Layout from '../comoponents/templates/Layout'
import { AddressSelectModal, TimeSelectModal, PaymentSelectModal } from "../comoponents/organisms"

const useStyles = makeStyles({
    root: {
    },
});

export default ({ match, className, ...others }) => {
    const classes = useStyles();
    const { params } = match
    const id = params.id
    return (
        <Layout>
            <Video id={id} />
            <ProductModals />
            <ProductDetailModal />
            <AddressSelectModal />
            <TimeSelectModal />
            <PaymentSelectModal />
        </Layout>
    )
}