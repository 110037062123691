import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import VerticalCenter from "../molecules/VerticalCenter"
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Store } from "../../store"
import clsx from "clsx"
const style = {
    root: {
        "position": "fixed",
        "zIndex": "20",
        "top": "0",
        "bottom": "56px",
        "left": "0",
        "right": "0",
        "overflow": "hidden",
        "&.close": {
            display: "none"
        }
    },
    background: {
        "left": "0px",
        "background": "rgba(0, 0, 0, 0.8)",
        "zIndex": 0,
        "position": "absolute",
        "width": "100%",
        "height": "100%",
        "top": '0',
        "bottom": "0",
        "left": "0",
        "right": "0",
    },
}

function CircularProgressWithLabel(props) {
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="static" {...props} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

const Loading = ({ closeButton, children, className, ...others }) => {
    const useStyles = makeStyles(style);
    const classes = useStyles();
    const { globalState, setGlobalState } = useContext(Store)
    return (
        <>
            <div className={clsx(classes.root, { 'close': !globalState.loading }, className)} {...others}>
                <div className={classes.background} ></div>
                <VerticalCenter>
                    {
                        globalState.progress == 100
                            ? <CircularProgress color="secondary" />
                            : <CircularProgressWithLabel color="secondary" value={globalState.progress} />
                    }
                </VerticalCenter>
            </div>
        </>
    )
}
export default Loading