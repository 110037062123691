import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Text, Button } from '../../atoms';

const useStyles = makeStyles({
    root: {
        width: "100%",
    },
    text: {
        padding: "0 50px 0 50px"
    },
    button: {
        padding: "35px 11px 0 11px"
    },
});

export default () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.text}>
                <Text>Create an account to create lists and post videos with your profile</Text>
            </div>
            <div className={classes.button}>
                <Button variant="contained" color="primary" fullWidth>Sign in to create a profile</Button>
            </div>
        </div >
    )
}