import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Text } from "../atoms/"

const useStyles = makeStyles({
    root: {
        width: "100%",
    },
    content: {},
    title: {
        display: "table",
        width: "100%",
        height: "35px"
    },
    titleText: {
        display: "table-cell",
        verticalAlign: "middle",
        paddingLeft: "40px",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "bold",
        "fontSize": "14px",
        "lineHeight": "20px",
        "color": "#FFFFFF"
    },
    arrowiconContainer: {
        display: "table-cell",
        verticalAlign: "middle",
        textAlign: "right",
    },
    arrowicon: {
        marginLeft: "auto",
        marginRight: "21px",
    },
});

export default ({ text, children, onClick }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.title} onClick={onClick}>
                <div className={classes.titleText}>
                    <Text>{text}</Text>
                </div>
                <svg className={classes.arrowiconContainer} width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.645917 0.645978C0.692363 0.599415 0.747538 0.562472 0.808283 0.537266C0.869028 0.512059 0.93415 0.499084 0.999917 0.499084C1.06568 0.499084 1.13081 0.512059 1.19155 0.537266C1.2523 0.562472 1.30747 0.599415 1.35392 0.645978L7.35392 6.64598C7.40048 6.69242 7.43742 6.7476 7.46263 6.80834C7.48784 6.86909 7.50081 6.93421 7.50081 6.99998C7.50081 7.06574 7.48784 7.13087 7.46263 7.19161C7.43742 7.25236 7.40048 7.30753 7.35392 7.35398L1.35392 13.354C1.26003 13.4479 1.13269 13.5006 0.999917 13.5006C0.867141 13.5006 0.739804 13.4479 0.645917 13.354C0.55203 13.2601 0.499285 13.1328 0.499285 13C0.499285 12.8672 0.55203 12.7399 0.645917 12.646L6.29292 6.99998L0.645917 1.35398C0.599354 1.30753 0.562411 1.25236 0.537204 1.19161C0.511998 1.13087 0.499023 1.06575 0.499023 0.999978C0.499023 0.934211 0.511998 0.86909 0.537204 0.808345C0.562411 0.7476 0.599354 0.692424 0.645917 0.645978Z" fill="white" />
                </svg>
            </div>
            <div className={classes.content}>
                {children}
            </div>
        </div>
    )
}