import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx"
import { Button } from '../../atoms';

const useStyles = makeStyles({
    root: {
        width: "100%",
        height: "78px"
    },
    button: {
        marginTop: "13px",
        marginLeft: "2.5%",
        marginRight: "2.5%",
        width: "45%",
        borderRadius: 0
    },
    goBack: {
    },
    addToCart: {
    },
});

export default ({ onAddToCart, className, ...others }) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, className)} {...others}>
            <Button variant="contained" color="secondary" className={clsx(classes.button, classes.goBack)}>Go Back</Button>
            <Button variant="contained" color="primary" className={clsx(classes.button, classes.addToCart)} onClick={onAddToCart} >Add To Cart</Button>
        </div>
    )
}