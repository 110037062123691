import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { TabPanel } from '../../atoms'
import clsx from "clsx"
import { theme } from '../../templates/ColorProvider'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    tab: {
        background: theme.palette.background.default,
        "&>span>svg": {
            color: theme.palette.text.disable,
        },
        "&.enable>span>svg": {
            color: theme.palette.text.primary,
        },
    },
}));

export default function SimpleTabs({ tab1Title, tab1Icon, tab1Content, tab2Title, tab2Icon, tab2Content }) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <Tabs value={value} onChange={handleChange}
                indicatorColor="#ffffff"
                textColor="primary"
                variant="fullWidth">
                <Tab icon={tab1Icon} label={tab1Title} className={clsx(classes.tab, { "enable": value == 0 })} />
                <Tab icon={tab2Icon} label={tab2Title} className={clsx(classes.tab, { "enable": value == 1 })} />
            </Tabs>
            <TabPanel value={value} index={0}>
                {tab1Content}
            </TabPanel>
            <TabPanel value={value} index={1}>
                {tab2Content}
            </TabPanel>
        </div >
    );
}