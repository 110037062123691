import React from 'react'
import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx"

const useStyles = makeStyles({
    root: {
    },
});

export default ({ file, setFile, className, children, ...others }) => {
    const classes = useStyles();
    // const [file, setFile] = useState("")
    const [fileName, setFileName] = useState("")
    const [fileData, setFileData] = useState("")
    const handleChange = (event) => {
        if (!event.target.files[0])
            return
        const file = event.target.files[0]
        let reader = new FileReader()
        reader.onloadend = () => {
            setFileData(reader.result);
            setFileName(file.name);
            setFile(file);
        }
        reader.readAsDataURL(file)
    }
    return (
        <div className={clsx(classes.root, className)} {...others}>
            <input
                accept="video/*"
                className={classes.input}
                style={{ display: 'none' }}
                id="FileUpload"
                multiple
                type="file"
                onChange={handleChange}
            />
            <label htmlFor="FileUpload">
                {fileName || children}
            </label>
        </div>
    )
}