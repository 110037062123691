import axios from 'axios'
import random from "./Random"

const validateZipcode = (zipcode) => {
    const pattern = /^([0-9]{3})-?[0-9]{4}$/;
    if (!zipcode.match(/^[0-9]{3}-?[0-9]{4}$/))
        return
    const first = zipcode.match(/^([0-9]{3})-?[0-9]{4}$/)[1]
    const second = zipcode.match(/^[0-9]{3}-?([0-9]{4})$/)[1]
    return [first, second]
}

export const getAddressBook = async (zipcode) => {
    try {
        const [first, second] = validateZipcode(zipcode)
        const res = await axios.get(`${process.env.REACT_APP_URL_ADDRESS_ZIP}/${first}/${second}.json`);
        return res.data.data;
    }
    catch (err) {
    }
}


export const getAddressList = async (userId) => {
    try {
        const address = { id: "1", zipCode: "1000001", address: "東京都千代田区千代田" }
        let data = [{ ...address, id: random() }, { ...address, id: random() }, { ...address, id: random() }, { ...address, id: random() }]
        // const res = await axios.get(`${process.env.REACT_APP_URL_ADDRESS_ZIP}/${first}/${second}.json`);
        return data;
    }
    catch (err) {
    }
}
