import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx"
import { VideoListListItem, VideoListListContainer } from '../../molecules'
import { useState, useEffect } from 'react'
import { getVideoProfile3x4 } from "../../../actions/__Video"

const useStyles = makeStyles({
    root: {
    },
});

export default ({ userId, key, className, ...others }) => {
    const classes = useStyles();
    const [data, setData] = useState([])
    const [name, setName] = useState([])
    const getData = async () => {
        const __data = await getVideoProfile3x4(userId, key)
        setData(__data.videos)
        setName(__data.name)
        console.log(__data)
    }
    useEffect(() => {
        getData()
        return () => { }
    }, [])
    return (
        <>
            <VideoListListContainer className={clsx(classes.root, className)} {...others}>
                {data.map(({ title, videoUploader, views, imgSrc }) => {
                    return (
                        <VideoListListItem title={title} videoUploader={videoUploader} views={views} imgSrc={imgSrc} />
                    )
                })}
            </VideoListListContainer>
        </>
    )
}