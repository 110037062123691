import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx"
import { ProductNoIcon, FullSizeModal, SearchInput2 } from '../molecules'
import { SearchProducts } from "../../actions/Search"
import { theme } from '../templates/ColorProvider'

const useStyles = makeStyles({
    root: {
        // position: "relative",
        background: "#181818",
        "width": "100%",
    },
    header: {
        "width": "100%",
        "height": "145px",
        "borderBottom": "0.5px solid #FFFFFF",
        "marginBottom": "8px",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "bold",
        "fontSize": "16px",
        "lineHeight": "23px",
        "color": "#FFFFFF",
    },
    header1st: {
        "width": "100%",
        "height": "80px",
    },
    headerIcon: {
        float: "left",
        margin: "32px 0 0 18px",
    },
    headerText: {
        "float": "left",
        "width": "calc(100% - 100px)",
        "height": "22px",
        "padding": 0,
        "margin": "32px 0 0 32px",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "bold",
        "fontSize": "16px",
        "lineHeight": "23px",
        "color": "#FFFFFF",
    },
    searchInput: {
        width: "calc(100% - 40px)",
        clear: "both",
        margin: "3px 20px 0 20px",
    },
    contents: {
        "height": "calc(100% - 145px - 8px - 1px - 52px)",
        paddingBottom: "52px",
        "overflow": "scroll",
    },
    product: {
        float: "left",
        width: "calc(100% - 66px - 18px)",
        margin: "10px 0 0 18px",
    },
    icon: {
        float: "left",
        margin: "31px 0 0 18px",
    },
    button: {
        "height": "35px",
        "padding": "17px 0 0 0",
        "textAlign": "center",
        "position": "absolute",
        "bottom": "0",
        "left": "0",
        "right": "0",
        "zIndex": "1",
        "background": theme.palette.secondary.main,
        "color": theme.palette.secondary.contrastText,
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "bold",
        "fontSize": "14px",
        "lineHeight": "20px",
        "alignItems": "center",
        "textAlign": "center",
        "color": "#FFFFFF"
    }
});

export default ({ productAdded, setProductAdded, open, setOpen, className, ...others }) => {
    const classes = useStyles();
    const [data, setData] = useState([])
    const [products, setProducts] = useState([])
    const [keyword, setKeyword] = useState("")

    const getProducts = async () => {
        const products = data.map((product) => {
            product.added = productAdded.some((p) => product.id === p.id)
            return product
        })
        setProducts(products)
    }
    useEffect(() => {
        getProducts()
        return () => { }
    }, [data, productAdded])

    const getData = async () => {
        const data = await SearchProducts(keyword)
        setData(data)
    }
    useEffect(() => {
        getData()
        return () => { }
    }, [keyword])

    const addProduct = (product) => {
        if (productAdded.some((p) => product.id === p.id))
            setProductAdded(Array.isArray(productAdded) && productAdded.map((p) => (product.id === p.id) ? undefined : p).filter(e => !!e))
        else
            setProductAdded([...productAdded, product])
    }
    const Icon = ({ added, className, onClick }) => {
        if (!added)
            return (
                <svg onClick={onClick} className={className} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 3.9375C9.14918 3.9375 9.29226 3.99676 9.39775 4.10225C9.50324 4.20774 9.5625 4.35082 9.5625 4.5V9C9.5625 9.14918 9.50324 9.29226 9.39775 9.39775C9.29226 9.50324 9.14918 9.5625 9 9.5625H4.5C4.35082 9.5625 4.20774 9.50324 4.10225 9.39775C3.99676 9.29226 3.9375 9.14918 3.9375 9C3.9375 8.85082 3.99676 8.70774 4.10225 8.60225C4.20774 8.49676 4.35082 8.4375 4.5 8.4375H8.4375V4.5C8.4375 4.35082 8.49676 4.20774 8.60225 4.10225C8.70774 3.99676 8.85082 3.9375 9 3.9375Z" fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.4375 9C8.4375 8.85082 8.49676 8.70774 8.60225 8.60225C8.70774 8.49676 8.85082 8.4375 9 8.4375H13.5C13.6492 8.4375 13.7923 8.49676 13.8977 8.60225C14.0032 8.70774 14.0625 8.85082 14.0625 9C14.0625 9.14918 14.0032 9.29226 13.8977 9.39775C13.7923 9.50324 13.6492 9.5625 13.5 9.5625H9.5625V13.5C9.5625 13.6492 9.50324 13.7923 9.39775 13.8977C9.29226 14.0032 9.14918 14.0625 9 14.0625C8.85082 14.0625 8.70774 14.0032 8.60225 13.8977C8.49676 13.7923 8.4375 13.6492 8.4375 13.5V9Z" fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9 16.875C11.0886 16.875 13.0916 16.0453 14.5685 14.5685C16.0453 13.0916 16.875 11.0886 16.875 9C16.875 6.91142 16.0453 4.90838 14.5685 3.43153C13.0916 1.95468 11.0886 1.125 9 1.125C6.91142 1.125 4.90838 1.95468 3.43153 3.43153C1.95468 4.90838 1.125 6.91142 1.125 9C1.125 11.0886 1.95468 13.0916 3.43153 14.5685C4.90838 16.0453 6.91142 16.875 9 16.875ZM9 18C11.3869 18 13.6761 17.0518 15.364 15.364C17.0518 13.6761 18 11.3869 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948212 11.3869 0 9 0C6.61305 0 4.32387 0.948212 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18Z" fill="white" />
                </svg>
            )
        return (
            <svg onClick={onClick} className={className} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" >
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18 9C18 11.3869 17.0518 13.6761 15.364 15.364C13.6761 17.0518 11.3869 18 9 18C6.61305 18 4.32387 17.0518 2.63604 15.364C0.948212 13.6761 0 11.3869 0 9C0 6.61305 0.948212 4.32387 2.63604 2.63604C4.32387 0.948212 6.61305 0 9 0C11.3869 0 13.6761 0.948212 15.364 2.63604C17.0518 4.32387 18 6.61305 18 9ZM13.5337 5.59125C13.4534 5.51117 13.3577 5.44812 13.2524 5.40587C13.1471 5.36361 13.0344 5.34303 12.921 5.34534C12.8076 5.34765 12.6958 5.37281 12.5923 5.41931C12.4888 5.46582 12.3958 5.53271 12.3187 5.616L8.41162 10.5941L6.057 8.23837C5.89705 8.08933 5.6855 8.0082 5.46691 8.01205C5.24832 8.01591 5.03976 8.10446 4.88518 8.25905C4.73059 8.41364 4.64204 8.6222 4.63818 8.84079C4.63432 9.05938 4.71546 9.27093 4.8645 9.43088L7.84125 12.4087C7.92144 12.4888 8.01693 12.5519 8.12203 12.5942C8.22713 12.6366 8.33968 12.6573 8.45296 12.6552C8.56625 12.6531 8.67795 12.6282 8.78141 12.582C8.88486 12.5358 8.97795 12.4692 9.05512 12.3863L13.5461 6.7725C13.6992 6.61331 13.7838 6.40043 13.7817 6.17958C13.7796 5.95872 13.691 5.74749 13.5349 5.59125H13.5337Z" fill="#21EBA2" />
            </svg >
        )

    }
    const ArrowRight = ({ className, ...others }) => {
        return (
            <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.0002 11V13H8.00016L13.5002 18.5L12.0802 19.92L4.16016 12L12.0802 4.08002L13.5002 5.50002L8.00016 11H20.0002Z" fill="white" />
            </svg>
        )
    }
    const close = () => { setOpen(false) }
    return (
        <FullSizeModal open={open} setOpen={setOpen} className={clsx(classes.root, className)} {...others}>
            <div className={classes.header}>
                <div className={classes.header1st} onClick={close}>
                    <ArrowRight className={classes.headerIcon} />
                    <span className={classes.headerText}>write description</span>
                </div>
                <SearchInput2 value={keyword} setValue={setKeyword} className={classes.searchInput} />
            </div>
            <div className={classes.contents}>
                {Array.isArray(products) && products.map((product) => {
                    const { id, added, name, brandName, price, imgSrc, open } = product
                    return (
                        <div className={classes.productWrapper} >
                            <ProductNoIcon color="white" className={classes.product} name={name} brandName={brandName} price={price} imgSrc={imgSrc} />
                            <Icon className={classes.icon} added={added} onClick={() => addProduct(product)} />
                        </div>
                    )

                })}
            </div>
            <div className={classes.button} onClick={close}>Add {Array.isArray(productAdded) && productAdded.length} Item</div>
        </FullSizeModal>
    )
}