import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx"

const useStyles = makeStyles({
    root: {
        "display": "block",
        "width": "188px",
        "margin": "0px",
        "padding": "3px",
        "textAlign": "left",
        "wordBreak": "normal",
        "whiteSpace": "normal"
    },
    container: {
        "display": "block",
        "position": "relative",
        "width": "100%",
        "height": "285px",
    },
    image: {
        "borderRadius": "10px",
        "display": "block",
        "width": "100%",
        "height": "100%",
        "objectFit": "cover"
    },
    overlay: {
        "position": "absolute",
        "bottom": "0",
        "background": "rgba(0, 0, 0, 0.5)",
        "color": "white",
        "width": "calc(100% - 24px)",
        "transition": ".1s ease",
        "opacity": "1",
        "fontSize": "12px",
        "padding": "12px",
        "textAlign": "center"
    },
    title: {
        "height": "51px",
        "overflowX": "hidden",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "normal",
        "fontSize": "12px",
        "lineHeight": "17px",
        "color": "#ffffff"
    },
    uploader: {
        "fontSize": "10px",
        "color": "#979797"
    },
    views: {
        "fontSize": "10px",
        "color": "#979797"
    },
    relatedProductImage: {
        float: "left",
        width: "30px",
        height: "30px",
        objectFit: "cover",
    },
    relatedProductITitle: {
        float: "left",
        margin: "0 0 0 20px",
        textAlign: "left",
        width: "calc(100% - 30px - 20px)",
        height: "30px",
        "overflowX": "hidden",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "normal",
        "fontSize": "10px",
        "lineHeight": "14px",
        "color": "#FFFFFF",
    },
});

export default ({ title, videoUploader, views, imgSrc, relatedProduct, type, className, ...others }) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, className)} {...others}>
            <div className={classes.container}>
                <img src={imgSrc} alt="thumbnail" className={classes.image} />
                <div className={classes.overlay}>
                    <img src={relatedProduct.imgSrc} alt="thumbnail" className={classes.relatedProductImage} />
                    <div className={classes.relatedProductITitle}>{relatedProduct.title}</div>
                </div>
            </div>
            <div className={classes.title}>{title}</div>
            <div className={classes.uploader}>{videoUploader}</div>
            <div className={classes.views}>{views} views</div>
        </div>
    )
}