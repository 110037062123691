import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx"

const useStyles = makeStyles({
    root: {
        margin: "10px 0",
        height: "1px",
        width: "100%",
        background: "#F2F2F2"
    },
});

export default ({ className, ...others }) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, className)} {...others}>

        </div>
    )
}