import axios from 'axios'
import URLJoin from '../URLJoin'
import AccessServer from '../AccessServer'

export default async (videoId, videoListId) => {
    try {
        if (AccessServer()) {
            const uri = URLJoin(process.env.REACT_APP_URL_BACKEND, "/api/addVideoToVideoList/")
            const param = { videoId, videoListId }
            const data = await axios.post(uri, param)
            return { ...data }
        } else {
            const param = { videoId, videoListId }
            const data = {}
            return { ...data }
        }
    }
    catch (err) {
        throw new Error("Network Error")
    }
}