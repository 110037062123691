import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Counter } from '../../atoms'
import { ContentsWrapper } from '../../molecules'
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from "clsx"

const useStyles = makeStyles({
    root: {
        position: 'relative',
        marginTop: "10px",
        height: '62px',
        width: '100%',
        padding: '0',
    },
    image: {
        "position": "absolute",
        "width": "40px",
        "height": "40px",
        "top": "14px",
        "left": "0",
        "objectFit": "cover"
    },
    name: {
        "position": "absolute",
        "overflow": "hidden",
        "width": "calc(100% - 54px - 130px)",
        "height": "42px",
        "left": "54px",
        "top": "0",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "normal",
        "fontSize": "10px",
        "lineHeight": "14px",
        "color": "#000000"
    },
    price: {
        "position": "absolute",
        "overflow": "hidden",
        "width": "calc(100% - 54px - 130px)",
        "height": "14px",
        "left": "54px",
        "top": "48px",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "normal",
        "fontSize": "10px",
        "lineHeight": "14px",
        "textAlign": "left",
        "color": "#000000"
    },
    counter: {
        "position": "absolute",
        "top": "18px",
        "right": "33px",
        "width": "85px",
        "height": "26px",
    },
    deleteButton: {
        "position": "absolute",
        "height": "16px",
        "width": "16px",
        "right": "0px",
        "top": "21px",
        "color": "#C4C4C4"
    },
});

export default ({ name, brandName, imgSrc, price, className, amount, setAmount, ...others }) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, className)} {...others}>
            <img className={classes.image} src={imgSrc} />
            <div className={classes.name}>{name}</div>
            <Counter className={classes.counter} value={amount} setValue={setAmount}></Counter>
            <div className={classes.price}>￥ {price}</div>
            {/* <DeleteIcon className={classes.deleteButton} onClick={() => { console.log("aa") }} /> */}
            <svg onClick={() => { setAmount(0) }} className={classes.deleteButton} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM11.854 4.854C11.9479 4.76011 12.0006 4.63278 12.0006 4.5C12.0006 4.36722 11.9479 4.23989 11.854 4.146C11.7601 4.05211 11.6328 3.99937 11.5 3.99937C11.3672 3.99937 11.2399 4.05211 11.146 4.146L8 7.293L4.854 4.146C4.80751 4.09951 4.75232 4.06264 4.69158 4.03748C4.63084 4.01232 4.56574 3.99937 4.5 3.99937C4.43426 3.99937 4.36916 4.01232 4.30842 4.03748C4.24768 4.06264 4.19249 4.09951 4.146 4.146C4.09951 4.19249 4.06264 4.24768 4.03748 4.30842C4.01232 4.36916 3.99937 4.43426 3.99937 4.5C3.99937 4.56574 4.01232 4.63084 4.03748 4.69158C4.06264 4.75232 4.09951 4.80751 4.146 4.854L7.293 8L4.146 11.146C4.05211 11.2399 3.99937 11.3672 3.99937 11.5C3.99937 11.6328 4.05211 11.7601 4.146 11.854C4.23989 11.9479 4.36722 12.0006 4.5 12.0006C4.63278 12.0006 4.76011 11.9479 4.854 11.854L8 8.707L11.146 11.854C11.1925 11.9005 11.2477 11.9374 11.3084 11.9625C11.3692 11.9877 11.4343 12.0006 11.5 12.0006C11.5657 12.0006 11.6308 11.9877 11.6916 11.9625C11.7523 11.9374 11.8075 11.9005 11.854 11.854C11.9005 11.8075 11.9374 11.7523 11.9625 11.6916C11.9877 11.6308 12.0006 11.5657 12.0006 11.5C12.0006 11.4343 11.9877 11.3692 11.9625 11.3084C11.9374 11.2477 11.9005 11.1925 11.854 11.146L8.707 8L11.854 4.854Z" fill="#C4C4C4" />
            </svg>

        </div>
    )
}