import axios from 'axios'
import URLJoin from '../URLJoin'
import AccessServer from '../AccessServer'

const getVideo = async (videoId) => {
    try {
        if (AccessServer()) {
            const uri = !!videoId
                ? URLJoin(process.env.REACT_APP_URL_BACKEND, "/api/video/", videoId + "")
                : URLJoin(process.env.REACT_APP_URL_BACKEND, "/api/video")
            const { data: data, ...other } = await axios.get(uri)
            const retrunData = { ...data }
            if (!retrunData.videoSrc.startsWith("https://"))
                return await getVideo(retrunData.nextVideoId)
            const products = retrunData.products.map(({ product }) => {
                return {
                    id: product.id,
                    imgSrc: product.images[0].src,
                    variantId: product.variants[0].id,
                    price: product.variants[0].price - 0,
                    discountedPrice: product.variants[0].price - 0,
                    brandName: product.vendor,
                    name: product.title,
                }
            })
            return { ...retrunData, products }
        } else {
            const products = {
                imgSrc: "https://www.durianhunter.com/wp-content/uploads/2017/08/IMG_0653-556x788.jpg",
                brandName: "Brand Name",
                name: "商品１",
                price: 1000,
                id: 1000,
            }
            const data = [
                {
                    videoId: 0,
                    videoSrc: "https://haneda-ec-contents-test.s3.us-east-2.amazonaws.com/test.mp4",
                    like: 123456,
                    videoUploader: "安藤亮介",
                    title: "藤本さんとハンバーガーを食べに来ました！めちゃめちゃ美味しかったです！！！！！！！", nextVideoId: 1,
                    products: [
                        { ...products },
                        { ...products, id: 1 },
                    ]
                },
                {
                    videoId: 1,
                    videoSrc: "https://haneda-ec-contents-test.s3.us-east-2.amazonaws.com/fujimoto_eating.mov",
                    like: 123456,
                    userName: "藤本社長",
                    description: "藤本さんのハンバーガーを食べる映像をご覧ください！",
                    nextVideoId: 2,
                    products: [
                        { ...products },
                        { ...products, id: 1 },
                    ]
                },
            ]
            switch (videoId || 0) {
                case 0:
                    return { ...data[0] }
                    break;
                case 1:
                    return { ...data[1] }
                    break;
                default:
                    return { ...data[0] }
                    break;
            }
        }
    }
    catch (err) {
        return { error: true, payload: err }
    }
}
export default getVideo