import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx"

const useStyles = makeStyles({
    root: {
        "overflowY": "scroll",
        "whiteSpace": "nowrap",
        "& > *": {
            "margin": "6px",
            "display": "inline-block",
            "overflowY": "auto"
        }
    },
});

export default ({ className, children, ...others }) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, className)} {...others}>
            {children}
        </div>
    )
}