import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx"

const useStyles = makeStyles({
    root: {
        "display": "block",
        "width": "280px",
        "height": "72px",
        "margin": "0px",
        "padding": "3px",
        "textAlign": "left",
        "wordBreak": "normal",
        "whiteSpace": "normal",
    },
    imagewrapper: {
        "float": "left",
        "margin": "6px"
    },
    image: {
        "width": "60px",
        "height": "60px",
        "borderRadius": "10px",
        "display": "block"
    },
    textwrapper: {
        "float": "left",
        "margin": "12px 0 0 12px",
        "width": "calc(100% - 150px)",
        "height": "60px"
    },
    title: {
        "fontSize": "10px",
        "height": "28px",
        "overflowX": "hidden",
        "color": "#000000"
    },
    uploader: {
        "fontSize": "10px",
        "color": "#979797"
    },
    views: {
        "fontSize": "10px",
        "color": "#979797"
    },
});

export default ({ title, videoUploader, views, imgSrc, hashTag, className, ...others }) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, className)} {...others}>

            {/* <div className={Style.trend}> */}
            <div className={classes.imagewrapper}>
                <img src={imgSrc} alt="thumbnail" className={classes.image} />
            </div>
            <div className={classes.textwrapper}>
                <div className={classes.title}>{title}</div>
                <div className={classes.uploader}>#{hashTag || "no HashTag"}</div>
                <div className={classes.views}>{views} views</div>
            </div>
            {/* </div> */}
        </div >
    )
}