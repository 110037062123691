import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx"
import LocalMallIcon from '@material-ui/icons/LocalMall';
import { Text } from "../../atoms"
import { useHistory, } from "react-router-dom"

const useStyles = makeStyles({
    root: {
        width: "100%",
        height: "96px",
    },
    imagewrapper: {
        float: "left",
        width: "70px",
        height: "100%",
        marginLeft: "18px",
    },
    textwrapper: {
        float: "left",
        width: "calc(100% - 150px)",
        height: "100%",
        marginLeft: "13px"
    },
    iconwrapper: {
        float: "right",
        width: "20px",
        height: "100%",
        marginRight: "26px",
    },
    image: {
        width: "70px",
        height: "70px",
        marginTop: "13px",
    },
    brandName: {
        display: "block",
        marginTop: "13px",
        height: "14px",
        fontSize: "10px",
    },
    name: {
        display: "block",
        marginTop: "4px",
        height: "28px",
        fontSize: "10px",
    },
    price: {
        display: "block",
        marginTop: "6px",
        height: "14px",
        fontSize: "10px",
    },
    icon: {
        width: "20px",
        height: "20px",
        marginTop: "34px",
    },
});

export default ({ name, brandName, price, imgSrc, linkTo, className, ...others }) => {
    const classes = useStyles();
    const history = useHistory()
    return (
        <div className={clsx(classes.root, className)} {...others} onClick={() => { history.push(linkTo) }}>
            <div className={classes.imagewrapper}>
                <img src={imgSrc} alt="thumbnail" className={classes.image} />
            </div>
            <div className={classes.textwrapper}>
                <Text className={classes.brandName}>{brandName}</Text>
                <Text className={classes.name}>{name}</Text>
                <Text className={classes.price}>￥{price}</Text>
            </div>
            <div className={classes.iconwrapper}>
                <LocalMallIcon className={classes.icon} />
            </div>
        </div >
    )
}