import React, { useContext, useState } from 'react'
import { browserhistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { FullSizeModal } from '../molecules'
import clsx from "clsx"
import { Store } from "../../store"
import { useEffect } from 'react';
import { getPaymentList } from "../../actions/Payment"

const useStyles = makeStyles({
    root: {
        width: "100%",
    },
    paymentWrapper: {
        "width": "calc(100% - 40px)",
        "margin": "0 20px",
        "borderBottom": "0.5px solid #cfcfcf",
        "boxSizing": "border-box",
        "display": "inline-block",
    },
    cardNumber: {
        "float": "left",
        "width": "calc(100% - 60px)",
        "height": "17px",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "normal",
        "fontSize": "12px",
        "lineHeight": "17px",
        "color": "#000000",
        "flex": "none",
        "order": "0",
        "alignSelf": "center",
        "flexGrow": "0",
        "margin": "19.5px 0px"
    },
    cardBrandLogo: {
        "margin": "17px 0px 17px 0px",
        "float": "left",
        "width": "40px",
        "height": "20px",
        "flex": "none",
        "order": "1",
        "alignSelf": "center",
        "flexGrow": "0",
    },
    addButtonWrapper: {
        "width": "calc(100% - 40px)",
        "margin": "0 20px",
        "borderBottom": "0.5px solid #cfcfcf",
        "boxSizing": "border-box",
        "display": "inline-block",
    },
    text: {
        float: "left",
        "width": "calc(100% - 20px)",
        "height": "17px",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "normal",
        "fontSize": "12px",
        "lineHeight": "17px",
        "color": "#000000",
        "flex": "none",
        "order": "0",
        "alignSelf": "center",
        "flexGrow": "0",
        "margin": "16px 0px 16px 0px"
    },
    next: {
        "margin": "16px 0px 16px 0px",
        "float": "right",
        "width": "20px",
        "height": "14px",
        "fontFamily": "Noto Sans Japanese",
        "fontStyle": "normal",
        "fontWeight": "bold",
        "fontSize": "10px",
        "lineHeight": "14px",
        "color": "#000000",
        "flex": "none",
        "order": "1",
        "alignSelf": "center",
        "flexGrow": "0",
    },
});

export default ({ className, ...others }) => {
    const classes = useStyles();
    const { globalState, setGlobalState } = useContext(Store)
    const onClose = () => {
        setGlobalState({ type: "SET_OPEN_PAYMENT_SELECT_MODAL", payload: { openPaymentSelectModal: false } })
    }

    const [paymentList, setPaymentList] = useState([]);
    const getPayments = async () => {
        const paymentList = await getPaymentList()
        setPaymentList(paymentList)
        console.log(paymentList)
    }
    useEffect(() => {
        getPayments()
        return () => { }
    }, [])
    const setPayment = (id) => {
        setGlobalState({ type: "SET_PAYMENT", payload: { paymentId: id } })
    }

    const LogoJCB = () => {
        return (
            <svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0)">
                    <path d="M31.5352 19.081C31.5352 21.7364 29.3729 23.8987 26.7175 23.8987H0.504883V4.8177C0.504883 2.1623 2.66719 0 5.32258 0H31.5352V19.081Z" fill="white" />
                    <path d="M23 14.1874H24.9915C25.0485 14.1874 25.1812 14.1685 25.2382 14.1685C25.6176 14.0926 25.94 13.7512 25.94 13.277C25.94 12.8218 25.6176 12.4804 25.2382 12.3856C25.1812 12.3665 25.0675 12.3665 24.9916 12.3665H23.0001V14.1874H23Z" fill="url(#paint0_linear)" />
                    <path d="M24.764 1.61212C22.8673 1.61212 21.3119 3.14861 21.3119 5.06424V8.64909H26.1865C26.3004 8.64909 26.4331 8.64909 26.5279 8.668C27.628 8.72497 28.4436 9.29394 28.4436 10.2801C28.4436 11.0578 27.8936 11.7217 26.8693 11.8545V11.8924C27.9884 11.9682 28.8419 12.5942 28.8419 13.5615C28.8419 14.6047 27.8936 15.2875 26.6417 15.2875H21.293V22.3055H26.3572C28.2539 22.3055 29.8093 20.7691 29.8093 18.8533V1.61212H24.764Z" fill="url(#paint1_linear)" />
                    <path d="M25.6933 10.5079C25.6933 10.0526 25.3709 9.7491 24.9915 9.69213C24.9537 9.69213 24.8588 9.67322 24.8019 9.67322H23V11.3423H24.8019C24.8589 11.3423 24.9726 11.3423 24.9916 11.3234C25.371 11.2664 25.6935 10.963 25.6935 10.5078L25.6933 10.5079Z" fill="url(#paint2_linear)" />
                    <path d="M5.68294 1.61212C3.78622 1.61212 2.23082 3.14861 2.23082 5.06424V13.5806C3.19822 14.0547 4.20343 14.3582 5.20876 14.3582C6.40367 14.3582 7.04852 13.6375 7.04852 12.6512V8.63006H10.0074V12.6321C10.0074 14.1874 9.04016 15.4583 5.75882 15.4583C3.76731 15.4583 2.21191 15.0219 2.21191 15.0219V22.2864H7.27616C9.17288 22.2864 10.7283 20.7501 10.7283 18.8343V1.61236H5.68282L5.68294 1.61212Z" fill="url(#paint3_linear)" />
                    <path d="M15.2236 1.61212C13.3269 1.61212 11.7715 3.14861 11.7715 5.06424V9.57842C12.6441 8.83867 14.1614 8.36449 16.6082 8.4783C17.9169 8.53527 19.3205 8.89564 19.3205 8.89564V10.3561C18.6186 9.99576 17.7842 9.67321 16.703 9.59733C14.8442 9.46461 13.7252 10.375 13.7252 11.9682C13.7252 13.5805 14.8442 14.4909 16.703 14.3392C17.7842 14.2633 18.6188 13.9219 19.3205 13.5805V15.041C19.3205 15.041 17.9358 15.4013 16.6082 15.4583C14.1614 15.572 12.6441 15.0978 11.7716 14.3582V22.3244H16.8358C18.7326 22.3244 20.2878 20.788 20.2878 18.8722V1.61236H15.2236V1.61212Z" fill="url(#paint4_linear)" />
                </g>
                <defs>
                    <linearGradient id="paint0_linear" x1="21.3087" y1="13.2792" x2="29.8323" y2="13.2792" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#007940" />
                        <stop offset="0.2285" stop-color="#00873F" />
                        <stop offset="0.7433" stop-color="#40A737" />
                        <stop offset="1" stop-color="#5CB531" />
                    </linearGradient>
                    <linearGradient id="paint1_linear" x1="21.3086" y1="11.9505" x2="29.8326" y2="11.9505" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#007940" />
                        <stop offset="0.2285" stop-color="#00873F" />
                        <stop offset="0.7433" stop-color="#40A737" />
                        <stop offset="1" stop-color="#5CB531" />
                    </linearGradient>
                    <linearGradient id="paint2_linear" x1="21.3085" y1="10.5054" x2="29.8325" y2="10.5054" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#007940" />
                        <stop offset="0.2285" stop-color="#00873F" />
                        <stop offset="0.7433" stop-color="#40A737" />
                        <stop offset="1" stop-color="#5CB531" />
                    </linearGradient>
                    <linearGradient id="paint3_linear" x1="2.2269" y1="11.9505" x2="10.8823" y2="11.9505" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#1F286F" />
                        <stop offset="0.4751" stop-color="#004E94" />
                        <stop offset="0.8261" stop-color="#0066B1" />
                        <stop offset="1" stop-color="#006FBC" />
                    </linearGradient>
                    <linearGradient id="paint4_linear" x1="11.7224" y1="11.9504" x2="20.1288" y2="11.9504" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#6C2C2F" />
                        <stop offset="0.1735" stop-color="#882730" />
                        <stop offset="0.5731" stop-color="#BE1833" />
                        <stop offset="0.8585" stop-color="#DC0436" />
                        <stop offset="1" stop-color="#E60039" />
                    </linearGradient>
                    <clipPath id="clip0">
                        <rect width="31.04" height="24" fill="white" transform="translate(0.5)" />
                    </clipPath>
                </defs>
            </svg>)
    }

    return (
        <>
            <FullSizeModal open={globalState.openPaymentSelectModal} onClose={onClose} className={clsx(classes.root, className)} {...others} >
                {paymentList.map(({ id, last4number, type }) => {
                    return (
                        <div className={classes.paymentWrapper}>
                            <div className={classes.cardBrandLogo}>
                                <LogoJCB />
                            </div>
                            <div className={classes.cardNumber}
                                onClick={() => {
                                    setPayment(id)
                                    onClose()
                                }}>
                                **** {last4number}
                            </div>
                        </div>
                    )
                })}
                <div className={classes.addButtonWrapper}>
                    <div className={classes.text}>
                        Add new credit card
                    </div>
                    <div className={classes.next}>
                        <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.145917 0.146039C0.192363 0.0994761 0.247538 0.0625333 0.308283 0.0373268C0.369028 0.0121204 0.43415 -0.000854492 0.499917 -0.000854492C0.565684 -0.000854492 0.630806 0.0121204 0.691551 0.0373268C0.752296 0.0625333 0.807471 0.0994761 0.853917 0.146039L6.85392 6.14604C6.90048 6.19248 6.93742 6.24766 6.96263 6.3084C6.98784 6.36915 7.00081 6.43427 7.00081 6.50004C7.00081 6.56581 6.98784 6.63093 6.96263 6.69167C6.93742 6.75242 6.90048 6.80759 6.85392 6.85404L0.853917 12.854C0.760031 12.9479 0.632693 13.0007 0.499917 13.0007C0.367141 13.0007 0.239804 12.9479 0.145917 12.854C0.0520303 12.7602 -0.000714779 12.6328 -0.000714779 12.5C-0.000714779 12.3673 0.0520303 12.2399 0.145917 12.146L5.79292 6.50004L0.145917 0.854039C0.0993537 0.807594 0.0624107 0.752418 0.0372043 0.691673C0.0119978 0.630928 -0.000976562 0.565807 -0.000976562 0.500039C-0.000976562 0.434272 0.0119978 0.369151 0.0372043 0.308406C0.0624107 0.247661 0.0993537 0.192485 0.145917 0.146039Z" fill="black" />
                        </svg>
                    </div>
                </div>
            </FullSizeModal>
        </>
    )
}