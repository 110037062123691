import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx"
import { VideoListMidiumContainer, VideoListMidiumItem } from '../molecules'
import { useContext, useEffect } from 'react'
import { Store } from "../../store"

const useStyles = makeStyles({
    root: {
    },
});

export default ({ className, ...others }) => {
    const classes = useStyles();
    const { globalState, setGlobalState } = useContext(Store)
    console.log(globalState)
    if (globalState.searchResult.def.videos.length === undefined || globalState.searchResult.def.videos.length == 0)
        return (<></>)

    return (
        <>
            <VideoListMidiumContainer className={clsx(classes.root, className)} {...others}>
                {globalState.searchResult.def.videos.map(({ title, videoUploader, views, imgSrc }) => {
                    return (
                        <VideoListMidiumItem title={title} videoUploader={videoUploader} views={views} imgSrc={imgSrc} />
                    )
                })}
            </VideoListMidiumContainer>
        </>
    )
}