import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx"

const useStyles = makeStyles({
    root: {
        width: "100%",
        "&>*": {
            float: "left",
            width: "33%",
            margin: "0"
        },
        "&>*:nth-child(n+13)": {
            display: "none"
        }
    },
});

export default ({ children, className, ...others }) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, className)} {...others}>
            {children}
        </div>
    )
}