import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx"
import { Text, MailIcon } from "../../atoms"

const useStyles = makeStyles({
    root: {
    },
    iconwrapper: {
        marginTop: 0,
        width: 82,
        height: 82,
        marginLeft: "auto",
        marginRight: "auto",
    },
    icon: {
        width: 82,
        height: 82,
        marginLeft: "auto",
        marginRight: "auto",
    },
    text: {
        marginTop: 20,
        display: "block",
        width: "100%",
        height: "40px",
        textAlign: "center",
        fontSize: "14px",
        fontWeight: "bold",
    },
    text2: {
        marginTop: 0,
        display: "block",
        width: "100%",
        height: "40px",
        textAlign: "center",
        fontSize: "14px",
    },
});

export default ({ className, ...others }) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, className)} {...others}>
            <div className={classes.iconwrapper}><MailIcon className={classes.icon} /></div>
            <Text className={classes.text}>Thank you for signing up!</Text>
            <Text className={classes.text2}>We’ve just sent you a verification email</Text>
        </div>
    )
}