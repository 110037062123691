import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Video from "./pages/Video"
import Search from "./pages/Search"
import AddVideo from "./pages/AddVideo"
import Message from "./pages/Message"
import SignUp from "./pages/SignUp"
import SignIn from "./pages/SignIn"
import Profile from "./pages/Profile"
import Product from "./pages/Product"

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Video} />
        <Route exact path="/Video" component={Video} />
        <Route exact path="/Video/:id" component={Video} />
        <Route exact path="/Search" component={Search} />
        <Route exact path="/AddVideo" component={AddVideo} />
        <Route exact path="/Message" component={Message} />
        <Route exact path="/CreateAccount" component={SignUp} />
        <Route exact path="/SignIn" component={SignIn} />
        <Route exact path="/Profile" component={Profile} />
        <Route exact path="/Profile/:id" component={Profile} />
        <Route exact path="/Product/:id" component={Product} />
      </Switch>
    </Router>
  );
}

export default App;